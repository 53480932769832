import Cookies from 'js-cookie'

const TokenKeys = 'token'
const companyId = 'companyId'
const companyName = 'companyName'
const isHumanResources = 'isHumanResources'
const MemberId = 'MemberId'

export function getToken () {
  return Cookies.get(TokenKeys)
}
export function getMemberId () {
  return Cookies.get(MemberId)
}

export function setToken (token) {
  return Cookies.set(TokenKeys, token)
}
export function setMemberId (Member) {
  return Cookies.set(MemberId, Member)
}

export function removeToken () {
  return Cookies.remove(TokenKeys)
}

/* 当前企业ID */
export function getCompanyId () {
  return Cookies.get(companyId)
}

export function setCompanyId (id) {
  return Cookies.set(companyId, id)
}

/* 当前企业名称 */
export function getCompanyName () {
  return Cookies.get(companyName)
}

export function setCompanyName (name) {
  return Cookies.set(companyName, name)
}

/* 是否人力isHumanResources */
export function getIsHumanResources () {
  return Cookies.get(isHumanResources)
}

export function setIsHumanResources (value) {
  return Cookies.set(isHumanResources, value)
}
