<template>
  <div class="headNav">
    <el-affix :offset="35">
      <div class="hg64 lh64 b2577E3 relative">
        <el-row class="nav contentWarp">
          <el-col :span="21">
            <div class="flex align-item f_center pl10 navLink">
              <router-link to="/" class="c333" active-class="b333 c2577E3"
                >首页</router-link
              >
              <router-link
                to="/position"
                class="c333"
                :class="{'is-active':route.path === '/positionDetail'}"
                active-class="is-active"
                >职位</router-link
              >
              <router-link
                to="/company"
                class="c333"
                :class="{'is-active':route.path === '/companyDetail'}"
                active-class="is-active"
                >单位</router-link
              >
              <router-link
                to="/collegeStudents"
                class="c333"
                active-class="is-active"
                >大学生专区</router-link
              >
              <router-link
                to="/timeRecruitment"
                class="c333"
                active-class="is-active"
                >限时招聘</router-link
              >
              <!-- <router-link
                to="/needRecruitment"
                class="c333"
                active-class="b333 c2577E3"
                >随需随聘</router-link
              > -->
              <a
                href="https://wx.vzan.com/live/pc/channel-detail?liveId=1555777647&cid=235066"
                class="c333"
                target="_blank"
                active-class="is-active"
                >直播招聘</a
              >
              <router-link
                to="/offline"
                class="c333"
                :class="{'is-active': (route.path).indexOf('/offline/') !== -1}"
                active-class="is-active"
                >热门活动</router-link
              >
              <router-link
                to="/training"
                class="c333"
                :class="{'is-active': (route.path).indexOf('/training/') !== -1}"
                active-class="is-active"
                >就业培训</router-link
              >
              <router-link
                to="/information"
                class="c333"
                :class="{'is-active': route.path === '/informationDetail'}"
                active-class="is-active"
                >资讯</router-link
              >
              <router-link
                to="/exampleCity"
                class="c333"
                :class="{'is-active': route.path === '/exampleCity' || route.path === '/exampleCityDetail' }"
                active-class="is-active"
                >示范城市</router-link
              >
            </div>
          </el-col>
          <el-col :span="3">
            <el-row>
              <!-- <el-col
                :span="4"
                class="pointer flex justify_right"
                @click="drawer = true"
              >
                <span class="c333 fz14" v-if="token">企业招聘</span>
              </el-col> -->
              <!-- <el-col :span="8" class="flex justify_right">
                <router-link
                  v-if="token"
                  class="c333"
                  active-class="b333 c2577E3"
                  to="/personalResume"
                >
                  个人求职
                </router-link>
              </el-col> -->
              <el-col class="flex justify_right">
                <router-link
                  :to="{
                    name: 'UserLogin',
                    params: { path: path },
                  }"
                  v-if="!token"
                >
                  <span class="c333 loginBtn">登录 / 注册</span>
                </router-link>
                <!-- <el-col :span="1">
                <span class="c333 login-btn" @click="goLogin()">登录</span>
                </el-col> -->
                <!-- <div
                  v-if="token"
                  class="
                    textover
                    pl10
                    c333
                    flex
                    justify_center
                    align_item
                    pointer
                  "
                  @click="persopnDrawer = true"
                >
                  <img
                    v-if="userInfo.user?.avatar"
                    style="width: 30px; height: 30px; border-radius: 100px"
                    :src="`${userInfo.user?.avatar}`"
                  />
                  <ing
                    v-else
                    style="width: 30px; height: 30px; border-radius: 100px"
                    :src="
                      userInfo.user.memberGender === 'M'
                        ? require('../assets/avatar/avatar01.png').default
                        : require('../assets/avatar/avatar03.png').default
                    "
                  />
                  <span class="pl5 fz14">{{
                    userInfo.user.name || userInfo.user.mobile
                  }}</span>
                </div> -->
                <!-- <div style="height:100%;" class="flex justify_right align_item textover">
                  <el-dropdown
                    v-if="token"
                    trigger="hover"
                    class="c333 pointer wd120"
                  >
                    <div class="textover el-dropdown-link pl10">
                      {{ userInfo.user.name || userInfo.user.mobile
                      }}
                      <i class="el-icon-arrow-down el-icon--right c333"></i>
                    </div>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          icon="el-icon-user-solid"
                          @click="persopnDrawer = true"
                          >个人中心</el-dropdown-item
                        >
                        <el-dropdown-item icon="el-icon-error" @click="goOut"
                          >退出登录</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div> -->
              </el-col>
              <!-- <elcol :span="2">
                <el-divider
                  direction="vertical"
                  style="background: #f1f1f1"
                  v-if="token"
                ></el-divider>
                <span class="c333 fz13 pointer" v-if="token"
                  ><i class="el-icon-chat-dot-round"></i> 消息 (1)</span
                >
              </elcol> -->
              <!-- <el-col :span="4" class="flex justify_center">
                <el-popover placement="bottom" :width="80" trigger="hover">
                  <template #reference>
                    <span class="iconfont icon-weixin c333 pointer"></span>
                  </template>
                  <img
                    style="width: 150px; height: 150px"
                    :src="require('../assets/ewm.png').default"
                  />
                  <h4 class="f_center">微信公众号</h4>
                </el-popover>
              </el-col> -->
            </el-row>
          </el-col>
        </el-row>
        <div class="button_fx pointer" @click="backVisible = true">
          <img
            src="@/assets/button_fx.png"
          />
        </div>
        <div class="sidebar bf5f5f5">
          <div class="f_center lh25">
            <el-row>
              <el-col>
                <div class="pointer pt20 bfff textHover">
                  <router-link
                    :to="{
                      name: 'UserLogin',
                      params: { path: path },
                    }"
                    v-if="!token"
                  >
                    <i class="iconfont icon-lujing fz30 c666"></i>
                    <p class="fz12 c666">注册 / 登录</p>
                  </router-link>
                  <div
                    v-if="token"
                    class="textover c333 pointer"
                    @click="persopnDrawer = true"
                  >
                    <img
                      v-if="userInfo.user?.avatar"
                      style="width: 30px; height: 30px; border-radius: 100px"
                      :src="`${userInfo.user?.avatar}`"
                    />
                    <img
                      v-if="!userInfo.user?.avatar && !userInfo.user?.memberGender"
                      style="width: 30px; height: 30px; border-radius: 100px"
                      src="../assets/avatar/avatar01.png"
                    />
                     <img
                      v-if="!userInfo.user?.avatar && userInfo.user.memberGender === 'W'"
                      style="width: 30px; height: 30px; border-radius: 100px"
                      src="../assets/avatar/avatar03.png"
                    />
                    <p class="pl5 fz14 textover">
                      {{ userInfo.user.name || userInfo.user.mobile }}
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col class="bfff mt10" v-if="token">
                <div
                  class="pointer pt20 c2577E3 textHover"
                  @click="drawer = true"
                >
                  <!-- <i class="iconfont icon-jigouguanli1 fz30"></i> -->
                  <img
                    src="@/assets/svg/unit.svg"
                    class="block"
                    style="margin: 0 auto"
                    width="40"
                    height="40"
                  />
                  <p class="fz12">企业招聘</p>
                </div>
              </el-col>
              <el-col v-if="token">
                <div class="pointer pt20 ove bfff mt10 textHover">
                  <router-link to="/personalResume">
                    <!-- <i class="iconfont icon-wodejianli fz30 c666"></i> -->
                    <img
                      src="@/assets/svg/resume.svg"
                      class="block svg"
                      style="margin: 0 auto"
                      width="40"
                      height="40"
                    />
                    <p class="fz12 c666">我的简历</p>
                  </router-link>
                </div>
                <div
                  class="pointer pt20 bfff textHover svg"
                  @click="infoClick(1)"
                >
                  <!-- <i class="iconfont icon-feiji fz30 c666"></i> -->
                  <img
                    src="@/assets/svg/delivery.svg"
                    class="block svg"
                    style="margin: 0 auto"
                    width="40"
                    height="40"
                  />

                  <p class="fz12 c666">我的投递</p>
                </div>
                <div
                  class="pointer pt20 bfff textHover svg"
                  @click="infoClick(4)"
                >
                  <!-- <i class="iconfont icon-dianzan fz30 c666"></i> -->
                  <img
                    src="@/assets/svg/recommended.svg"
                    class="block svg"
                    style="margin: 0 auto"
                    width="40"
                    height="40"
                  />

                  <p class="fz12 c666">我的推荐</p>
                </div>
              </el-col>
              <el-col class="bfff mt10">
                <el-popover placement="left" :width="150" trigger="hover">
                  <template #reference>
                    <div class="pointer pt20 textHover">
                      <i class="iconfont icon-erweima fz30 c666"></i>
                      <p class="fz12">微信小程序</p>
                    </div>
                  </template>
                  <div class="f_center">
                    <p class="fz16">微信小程序</p>
                    <img width="140" height="140" src="@/assets/mimiAPP.jpg"/>
                    <p class="fz14 c666 pl20 pr10">请使用微信扫描二维码进入小程序</p>
                  </div>
                </el-popover>
              </el-col>
              <el-col class="bfff mt10">
                <div class="pointer pt20 textHover">
                  <router-link to="/feedback">
                    <i class="iconfont icon-yijianfankui fz30 c666"></i>
                    <p class="fz12 c666">意见反馈</p>
                  </router-link>
                </div>
              </el-col>

            </el-row>
          </div>
        </div>
      </div>
    </el-affix>
    <!-- 企业 -->
    <el-drawer
      :withHeader="false"
      v-model="drawer"
      direction="rtl"
      size="40%"
      :destroy-on-close="true"
    >
      <EnterpriseList></EnterpriseList>
    </el-drawer>
    <!-- 个人信息 -->
    <el-drawer
      :withHeader="false"
      v-model="persopnDrawer"
      direction="rtl"
      size="50%"
      :destroy-on-close="true"
    >
      <el-scrollbar>
        <PersonalCenter
          @colseResume="colseResume"
          :activeNum="activeNum"
        ></PersonalCenter>
      </el-scrollbar>
    </el-drawer>
    <!-- 返乡填写 -->
    <el-dialog
  v-model="backVisible"
>
  <img width="140" height="140" src="@/assets/mimiAPP.jpg"/>
  <p class="fz14 c666 ">请使用微信扫描二维码进入小程序填写</p>
</el-dialog>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getToken } from '@/utils/auth'
import { defineComponent, ref, watch, toRefs } from 'vue'
import EnterpriseList from './enterpriseList'
import PersonalCenter from './personalCenter.vue'

// import PersonalCenter from './personalInformationOld.vue'
export default defineComponent({
  components: { EnterpriseList, PersonalCenter },
  name: 'HeadersNav',
  props: {
    navCurrent: {
      type: Number,
      default: 1
    }
  },
  setup (props, context) {
    const route = useRoute()
    const store = useStore()
    console.log(store.state)
    const token = ref(getToken())
    const userInfo = ref(store.state.user)
    const companyList = ref(userInfo.value?.listCompany)
    watch(store.state?.user, (newValue, old) => {
      companyList.value = newValue.listCompany
    })

    /* 我要招人 */
    const drawer = ref(false)
    const path = ref(route.fullPath)
    const navCurrent = ref(props.navCurrent)
    function navCurrentCliCK (num) {
      navCurrent.value = num
    }
    /* 个人中心信息 */
    const persopnDrawer = ref(false)
    const colseResume = () => {
      persopnDrawer.value = false
    }
    const goOut = () => {
      store.dispatch('user/logout')
    }

    const { fullPath: routePath } = toRefs(route)

    watch(routePath, () => {
      drawer.value = false
      persopnDrawer.value = false
    })
    /* 侧边栏 */
    const activeNum = ref(1)
    const infoClick = (value) => {
      activeNum.value = value
      persopnDrawer.value = true
    }
    // 返乡
    const backVisible = ref(false)
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      navCurrent,
      route,
      path,
      navCurrentCliCK,
      userInfo,
      token,
      // goCompany,
      goOut,
      drawer,
      activeNum,
      persopnDrawer,
      companyList,
      colseResume,
      infoClick,
      backVisible
    }
  }
})
</script>

<style lang="scss" scoped>
.b028b8d {
  background: #d77d00;
}
::v-deep .el-drawer__header {
  margin-bottom: 5px !important;
  padding: 10px 10px 0;
}
.headNav {
  ::v-deep .el-affix--fixed {
    z-index: 102 !important;
  }
  .navLink a {
    width: 104px;
    // padding:0 24px;
  }
}
.sidebar {
  position: absolute;
  right: 0;
  top: 64px;
  z-index: 2000;
  width: 70px;
  box-shadow: 0 0 10px #ccc;
  a {
    padding: 0;
  }
}
.button_fx{
  position: absolute;
  right: 10px;
  top: -78px;
  z-index: 2000;
  img{
    width:185px;
    height:95px;
  }
}
.loginBtn {
  border: 1px solid #333;
  padding: 8px 10px;
  border-radius: 5px;
  transition: all 0.5s;
  &:hover {
    background: #333;
    color: #ffcd1e;
  }
}
.login-btn{
  border: 1px solid #333;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  margin: 12px 0;
  transition: all 0.5s;
  padding:0 8px;
  cursor: pointer;
  &:hover{
    background: #333;
    color: #ffcd1e
  }
}
.fixedRight {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  width: 40px;
  height: 100vh;
  background: #fff;
  box-shadow: 0 10px 5px #f1f1f1;
}
.is-active{
  background:#333333;
  color:#FFCD1E;
}
::v-deep .el-dialog{
  width: 190px;
  margin-top:100px;
  height: 300px;
}
</style>
