
import { useRouter } from 'vue-router'
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  name: 'PopupIdentity',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const router = useRouter()
    const dialogVisibleTF = ref<boolean>(false)
    watch(props, (newValue) => {
      dialogVisibleTF.value = newValue.modelValue
    }, { deep: true, immediate: true })

    const goFindHandler = (value:String) => {
      if(value === 'job') {
        router.push({name:'PersonalResume'})
      } else if(value === 'people') {
        router.push({name:'JoinWe'})
      }
      window.localStorage.setItem("popupIdentity", 'true')
    }

    /* 关闭回调 */
    const closeHandler = () => {
      window.localStorage.setItem("popupIdentity", 'true')
      context.emit('update:modelValue', false)
    }
    return {
      dialogVisibleTF,
      closeHandler,
      goFindHandler
    }
  }
})
