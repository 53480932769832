<template>
  <div class="login-box-all">
    <div class="login-box">
      <div class="login-box-img">
        <router-link to="/" class="c333" active-class="b333 c2577E3">
          <img src="../../assets/login-logo-white.png" />
        </router-link>
      </div>
      <div class="login-box-form">
        <div class="login-box-img">
          <img src="../../assets/topImg/slogan.png" />
        </div>
        <div v-if="!isBind" class="login-form">
          <div class="login-way">
            <div>使用第三方账号登录</div>
            <!-- <div @click="wechatLogin">微信登录</div> -->
            <img src="@/assets/svg/wechat.svg" @click="wechatLogin" />
            <!-- <i ></i> -->
          </div>
          <div class="login-by-phone">
            <div style="margin-bottom: 20px">使用手机号登录</div>
            <el-form
              ref="ruleFormRef"
              :rules="loginFormRules"
              :model="loginForm"
            >
              <el-row class="row-bg" justify="center">
                <el-col :span="16">
                  <el-form-item prop="mobile">
                    <el-input
                      placeholder="请输入手机号码"
                      v-model="loginForm.mobile"
                      class="hvr-underline-from-center"
                      @keyup.enter="submitForm"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- prop="verificationCode" -->
              <el-row class="row-bg" justify="center">
                <el-col :span="16">
                  <el-form-item prop="verificationCode">
                    <div>
                      <el-input
                        placeholder="请输入验证码"
                        v-model="loginForm.verificationCode"
                        @keyup.enter="submitForm"
                      >
                        <template #append>
                          <el-button
                            :disabled="coolTime > 0"
                            @click="getCode"
                            >{{
                              coolTime > 0
                                ? "(" + coolTime + "秒)"
                                : "获取动态码"
                            }}</el-button
                          >
                        </template>
                      </el-input>
                    </div>
                    <!-- <el-col :span="8">
                          <el-button
                            type="text"
                            :disabled="coolTime > 0"
                            @click="getCode"
                            >{{
                              coolTime > 0 ? "(" + coolTime + "秒)" : "获取动态码"
                            }}</el-button
                          >
                        </el-col> -->
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-form-item prop="password">
                  <el-input
                    placeholder="请输入密码"
                    v-model="loginForm.password"
                    class="hvr-underline-from-center"
                  ></el-input>
                </el-form-item> -->
              <el-form-item class="flex justify_center">
                <p>
                  <el-checkbox
                    v-model="agreeChecked"
                    @keyup.enter="submitForm"
                  ></el-checkbox>
                  <span> 接受</span>
                  <span class="pointer" @click="rulesDetailTF = true"
                    >《用户信息隐私权规定及法律声明服务协议》</span
                  >
                </p>
              </el-form-item>
              <el-row class="row-bg" justify="center">
                <el-col :span="16">
                  <el-form-item class="flex justify_center">
                    <el-button
                      type="primary"
                      :class="{ loginBtn: true, 'op-half': loginBtnDisable }"
                      @click="submitForm"
                      style="color: #333"
                      >一键登录 / 注册</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div v-if="isBind" class="login-form">
          <!-- <div class="login-way">
            <div>使用第三方账号登录</div>
            <div @click="wechatLogin">微信登录</div>
          </div> -->
          <div class="login-by-phone">
            <div style="margin-bottom: 20px; padding-top: 40px">绑定手机号</div>
            <el-form ref="ruleFormRef" :rules="bindFormRules" :model="bindForm">
              <el-row class="row-bg" justify="center">
                <el-col :span="16">
                  <el-form-item prop="mobile">
                    <el-input
                      placeholder="请输入手机号码"
                      v-model="bindForm.mobile"
                      class="hvr-underline-from-center"
                      @keyup.enter="bindPhone"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- prop="verificationCode" -->
              <el-row class="row-bg" justify="center">
                <el-col :span="16">
                  <el-form-item prop="verificationCode">
                    <el-input
                      placeholder="请输入验证码"
                      v-model="bindForm.verificationCode"
                      @keyup.enter="bindPhone"
                    >
                      <template #append>
                        <el-button
                          :disabled="codeCoolTime > 0"
                          @click="getVerifyCode"
                          >{{
                            codeCoolTime > 0
                              ? "(" + codeCoolTime + "秒)"
                              : "获取动态码"
                          }}</el-button
                        >
                      </template>
                    </el-input>
                    <!-- <el-col :span="8">
                          <el-button
                            type="text"
                            :disabled="coolTime > 0"
                            @click="getCode"
                            >{{
                              coolTime > 0 ? "(" + coolTime + "秒)" : "获取动态码"
                            }}</el-button
                          >
                        </el-col> -->
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-form-item prop="password">
                  <el-input
                    placeholder="请输入密码"
                    v-model="loginForm.password"
                    class="hvr-underline-from-center"
                  ></el-input>
                </el-form-item> -->
              <!-- <el-form-item class="flex justify_center">
                  <p>
                    <el-checkbox v-model="agreeChecked" @keyup.enter="submitForm"></el-checkbox>
                    <span> 接受</span>
                    <span class="pointer" @click="rulesDetailTF = true"
                      >《用户信息隐私权规定及法律声明服务协议》</span
                    >
                  </p>
                </el-form-item> -->
              <el-row class="row-bg" justify="center">
                <el-col :span="16">
                  <el-form-item class="flex justify_center">
                    <el-button
                      type="primary"
                      class="loginBtn"
                      @click="bindPhone"
                      >绑定手机号码</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer">
      服务热线：400-8061633（9:00-18:00） 企业服务邮箱：shspckj@163.com
      备案号：滇ICP备2021004711号-1 运营单位：昆明瀚鹏人力资源信息咨询有限公司
    </div>
    <!-- 企业认证规则详情 -->
    <AgreementHtml v-model="rulesDetailTF" agreement-type="U"></AgreementHtml>
  </div>
</template>
<script>
import { defineComponent, reactive, unref, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { sendVerificationCode, sendVerifyCode } from "@/api/user";
import AgreementHtml from "@/components/agreementHtml.vue";
import { debounce } from "@/utils";
export default defineComponent({
  components: { AgreementHtml },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const loginBtnDisable = ref(true);
    // const isLogin = ref(true)
    // 判断第三方登录的微信是否被绑定
    // const isBind = ref(store.state.isBind)
    const isBind = computed(() => store.state.user.isBind);
    console.log("isBind", store.state.user.isBind);
    watch(
      isBind,
      (newValue) => {
        console.log("value", newValue);
      },
      { deep: true, immediate: true }
    );
    // console.log(route.params)
    const backPath = ref(route.params?.path);
    const agreeChecked = ref(false);
    console.log("backPath", backPath);
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1(3|4|5|6|7|8)\d{9}$/;
        // const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        if (reg.test(value.trim())) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    const ruleFormRef = ref(null);
    const loginForm = reactive({
      mobile: null,
      verificationCode: null,
      password: null,
    });
    const loginFormRules = {
      mobile: [{ required: true, validator: checkPhone, trigger: "blur" }],
      verificationCode: [
        { required: true, message: "请输入验证码", trigger: "blur" },
        { min: 4, max: 4, message: "请输入四位验证码", trigger: "blur" },
      ],
      password: [{ required: true, message: "请输入密码", trigger: "blur" }],
    };
    const coolTime = ref(0); // 验证码倒计时
    const getCode = debounce(async () => {
      if (!loginForm.mobile || loginForm.mobile.trim().length !== 11) {
        ElMessage.error({
          message: "请输入正确的手机号码",
          type: "error",
        });
      } else {
        const data = {
          // mobile: loginForm.mobile.trim()
          appId: process.env.VUE_APP_APPID,
          phone: loginForm.mobile.trim(),
        };
        const resp = await sendVerificationCode(data);
        if (resp.code === "0") {
          ElMessage.success({
            message: "发送成功",
            type: "success",
          });
          coolTime.value = 60;
          let timer = {};
          if (timer) {
            clearInterval(timer);
          }
          timer = setInterval(() => {
            coolTime.value -= 1;
            if (coolTime.value <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          ElMessage.warning(resp.message);
        }
      }
    }, 500);
    watch(
      loginForm,
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        form.validate((ok) => {
          loginBtnDisable.value = !ok;
          form.clearValidate();
        });
      },
      { deep: true }
    );
    const submitForm = async () => {
      const form = unref(ruleFormRef);
      if (!form) return;
      await form.validate();
      const { mobile, verificationCode, password } = loginForm;
      const data = {
        mobile: mobile,
        verificationCode: verificationCode,
        password: password,
        path: backPath.value,
      };
      if (!agreeChecked.value) {
        ElMessage.warning("请您同意协议");
      } else {
        store.dispatch("user/login", data);
      }
    };

    // 绑定微信
    const bindForm = reactive({
      mobile: null,
      verificationCode: null,
      password: null,
    });
    const bindFormRules = {
      mobile: [{ required: true, validator: checkPhone, trigger: "blur" }],
      verificationCode: [
        { required: true, message: "请输入验证码", trigger: "blur" },
        { min: 4, max: 4, message: "请输入四位验证码", trigger: "blur" },
      ],
      password: [{ required: true, message: "请输入密码", trigger: "blur" }],
    };
    // 绑定验证码
    const codeCoolTime = ref(0);
    const getVerifyCode = debounce(async () => {
      if (!bindForm.mobile || bindForm.mobile.trim().length !== 11) {
        ElMessage.error({
          message: "请输入正确的手机号码",
          type: "error",
        });
      } else {
        let appId = "";
        if (process.env.NODE_ENV === "development") {
          appId = "recruitment-dev";
        } else {
          appId = "recruitment";
        }
        const data = {
          phone: bindForm.mobile.trim(),
          appId: appId,
        };
        const resp = await sendVerifyCode(data);
        if (resp.code === "0") {
          ElMessage.success({
            message: "发送成功",
            type: "success",
          });
          codeCoolTime.value = 60;
          let timer = {};
          if (timer) {
            clearInterval(timer);
          }
          timer = setInterval(() => {
            codeCoolTime.value -= 1;
            if (codeCoolTime.value <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          ElMessage.warning(resp.message);
        }
      }
    }, 500);

    // 绑定微信

    const bindPhone = async () => {
      const form = unref(ruleFormRef);
      if (!form) return;
      await form.validate();
      let appId = "";
      if (process.env.NODE_ENV === "development") {
        appId = "recruitment-dev";
      } else {
        appId = "recruitment";
      }
      const { mobile, verificationCode, password } = bindForm;
      const data = {
        mobile: mobile,
        verificationCode: verificationCode,
        password: password,
        path: backPath.value,
        appId: appId,
      };
      // if (!agreeChecked.value) {
      //   ElMessage.warning('请您同意协议')
      // } else {
      store.dispatch("user/bindPhone", data);
      // }
    };
    // 微信登录
    const codeValue = ref("");
    const wechatLogin = async () => {
      const appid = "wx98b30c05b848cd26";
      const redirectURL = encodeURIComponent("https://www.qihjob.com/");
      const scope = "snsapi_login";
      const state = "state";
      const method = "qrcode";
      const mode = "message";
      const loginurl = `https://auth.qihjob.com/wx_auth.html?appid=${appid}&method=${method}&redirectURL=${redirectURL}&scope=${scope}&state=${state}&mode=${mode}`;
      const newWindow = window.open(
        loginurl,
        "",
        "width=800,height=600,top=100px,left=20px"
      );
      window.onmessage = function (event) {
        if (event.type === "message") {
          newWindow.close();
          codeValue.value = event.data.code;
          console.log("aaa");
          const data = {
            configCode: "recruitment_wc_login",
            thirdPartCode: codeValue.value,
            path: backPath.value,
          };
          store.dispatch("user/wechatLogin", data);
        }
      };
    };

    /* 注册法律信息弹窗 */
    const rulesDetailTF = ref(false);
    return {
      loginForm,
      loginFormRules,
      submitForm,
      ruleFormRef,
      coolTime,
      backPath,
      getCode,
      agreeChecked,
      rulesDetailTF,
      wechatLogin,
      codeValue,
      isBind,
      bindForm,
      bindFormRules,
      getVerifyCode,
      codeCoolTime,
      bindPhone,
      loginBtnDisable,
    };
  },
});
</script>
<style lang="scss" scoped>
.login-box-all {
  background: url("../../assets/login-back.png") center center no-repeat;
  width: 100%;
  height: calc(100vh);
  position: relative;
  background-size: cover;
  box-sizing: border-box;
  z-index: 1;
  .login-box {
    width: 830px;
    margin: auto;
    padding-top: 30px;
  }
  .login-box-img {
    img {
      width: 353px;
      height: 66px;
    }
  }
  .login-box-form {
    width: 480px;
    // height: 510px;
    margin: 20px auto;
    .login-box-img {
      margin-left: 80px;
      img {
        height: 55px;
      }
    }
    .login-form {
      // height: 500px;
      background: #fff;
      border-radius: 10px;
      margin-top: 30px;
      .login-way {
        text-align: center;
        padding-top: 30px;
        color: #666;
        font-size: 16px;
        img {
          width: 50px;
          height: 50px;
          margin-top: 10px;
          cursor: pointer;
        }
      }
      .login-by-phone {
        text-align: center;
        color: #666;
        font-size: 16px;
        margin-top: 10px;
        padding-bottom: 20px;
        // .el-input{
        //   width: 250px
        // }
      }
    }
    .loginBtn {
      // margin-top: 20px;
      width: 100%;
      background: #ffcd1e;
      border: 0;
    }
    .el-input-group__append button {
      background: #ffcd1e;
      color: #333;
    }
  }
  .login-footer {
    color: #fff;
    // margin-top: 50px;
    // padding-bottom: 30px;
    font-size: 12px;
    width: 480px;
    margin: auto;
    // margin-top: 20px;
  }
}
</style>
