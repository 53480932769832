import request from '@/utils/request'
import { AxiosRequestConfig } from 'axios'

export function post<R> (url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
  return request.post(url, data, { retApiRes: false, ...config })
}

export function get<R> (url: string, config?: AxiosRequestConfig): Promise<R> {
  return request.get(url, { retApiRes: false, ...config })
}
