import request from '@/utils/request'
import service from '@/utils/service'
const BaseUrl = '/app/login'

export function sendVerificationCode (data) {
  return service({
    // url: '/basic/sendVerificationCode',
    url: '/sms/sendVerifyCode',
    method: 'post',
    data: data
  })
}
/* 个人信息 */
export function modifyUser (data) {
  return request({
    url: '/app/account/modifyUser',
    method: 'post',
    data: data
  })
}
export function login (data) {
  return service({
    // url: `${BaseUrl}/login`,
    url: '/auth2/loginRegister',
    method: 'post',
    data: data
  })
}
export function getUser (token) {
  return request({
    url: `${BaseUrl}/getUser`,
    method: 'post'
  })
}

export function logout () {
  return service({
    // url: `${BaseUrl}/logout`,
    url: '/auth2/logout',
    method: 'post'
  })
}
/* 获取用户信息 */
export function getUserDetail (data) {
  return request({
    url: '/app/member/detail',
    method: 'post',
    data: data
  })
}

// 微信登录
export function loginWechat (data) {
  return service({
    url: '/thirdAuth2/loginWechatByCode',
    method: 'post',
    data: data
  })
}

// 绑定微信
export function bindByCode (data) {
  return service({
    url: '/thirdAuth2/bindByCode',
    method: 'post',
    data: data
  })
}

// 没有账户绑定微信
export function bindWechat (data) {
  return service({
    url: '/thirdAuth2/loginBind',
    method: 'post',
    data: data
  })
}

// 绑定微信的验证码
export function sendVerifyCode (data) {
  return service({
    url: '/sms/sendVerifyCode',
    method: 'post',
    data: data
  })
}
