import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { class: "warp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LimitedRecruitment = _resolveComponent("LimitedRecruitment")!
  const _component_TopBoard = _resolveComponent("TopBoard")!
  const _component_HeaderNav = _resolveComponent("HeaderNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footers = _resolveComponent("Footers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (
          !_ctx.route.fullPath.includes('/enterpriseManagement') &&
          !_ctx.route.fullPath.includes('/userLogin')
        )
        ? (_openBlock(), _createBlock(_component_LimitedRecruitment, { key: 0 }))
        : _createCommentVNode("", true),
      (
          !_ctx.route.fullPath.includes('/login') &&
          !_ctx.route.fullPath.includes('/enterpriseManagement') &&
          !_ctx.route.fullPath.includes('/userLogin')
        )
        ? (_openBlock(), _createBlock(_component_TopBoard, { key: 1 }))
        : _createCommentVNode("", true),
      (
          !_ctx.route.fullPath.includes('/login') &&
          !_ctx.route.fullPath.includes('/enterpriseManagement') &&
          !_ctx.route.fullPath.includes('/userLogin')
        )
        ? (_openBlock(), _createBlock(_component_HeaderNav, { key: 2 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view),
      (
          !_ctx.route.fullPath.includes('/enterpriseManagement') &&
          !_ctx.route.fullPath.includes('/userLogin')
        )
        ? (_openBlock(), _createBlock(_component_Footers, { key: 3 }))
        : _createCommentVNode("", true)
    ])
  ]))
}