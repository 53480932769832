<template>
  <div class="bfff">
    <div class="contentWarp pt20">
      <!-- 热门城市 -->
      <el-row class="borf3f3f3b mb5">
        <el-col :span="12" class="flex align_item">
          <span class="fbold borb3FFCD1E lh35 fz24 c2577E3 pl10 pr10"
            >省内热门城市</span
          >
        </el-col>
        <el-col :span="12" class="lh35">
          <div class="flex justify_right">
            <router-link
              :to="{
                name: 'HotCityList',
                query: { level: 2 }
              }"
            >
              <span class="c999 fz14 textHover"
                >查看更多<i class="el-icon-arrow-right el-icon--right"></i
              ></span>
            </router-link>
          </div>
        </el-col>
      </el-row>
      <div class="cityCarousel mt10">
        <!-- <el-carousel :interval="5000" arrow="always" indicator-position='none' ref="carousel"> -->
        <!-- <el-carousel-item v-for="item in areaList" :key="item"> -->
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="pointer pb20"
            v-for="j in cityList"
            :key="j.code"
          >
            <div class="borf4f4f4 jobHover br10 ove">
              <router-link
                :to="{
                  name: 'Position',
                  query: { areaCode: j.code }
                }"
                target="_blank"
              >
                <div class="footerwh relative">
                  <img :src="j.imgUrl" width="282" height="196" />
                  <span class="address absolute cfff">
                    <i class="iconfont icon-dizhi fz22"></i>
                    <span class="fz18">{{ j.name }}</span>
                  </span>
                </div>
                <el-row class="lh40">
                  <el-col :span="12">
                    <span class="c333 pl10"
                      ><i
                        class="iconfont cccc icon-Id fz20 relative"
                        style="top:3px;"
                      ></i
                      ><span class="fz14 pl5">职位</span></span
                    >
                    <span class="c2577E3 fz14 pl10 fbold">
                      <!-- {{ cityTotalList?.[j.code]?.positionTotal
                        ? cityTotalList?.[j.code]?.positionTotal
                        : null}} -->
                        {{simplifyNumber(j.positionNum) }}
                    </span>
                  </el-col>
                  <el-col :span="12">
                    <span class="c333"
                      ><i class="iconfont icon-gangweiguanli cccc fz16"></i
                      ><span class="fz14 pl5">岗位</span></span
                    >
                    <span class="c2577E3 fz14 pl10 fbold">
                      <!-- {{
                      cityTotalList?.[j.code]?.stationTotal
                        ? cityTotalList?.[j.code]?.stationTotal
                        : null
                    }} -->
                    {{ simplifyNumber(j.postNum) }}
                    </span>
                  </el-col>
                  <el-col :span="12">
                    <span class="c333 pl10"
                      ><i
                        class="cccc el-icon-user-solid fz20 relative"
                        style="top:3px;"
                      ></i
                      ><span class="fz14 pl5">访问</span></span
                    >
                    <span class="c2577E3 fz14 pl10 fbold">
                      <!-- {{ cityTotalList?.[j.code]?.positionTotal
                        ? cityTotalList?.[j.code]?.positionTotal
                        : null}} -->
                        {{simplifyNumber(j.visitSum) }}
                    </span>
                  </el-col>
                  <el-col :span="12">
                    <span class="c333"
                      ><i class="iconfont icon-renyuanchurushenqing-zhuanyi-01 cccc fz20"></i
                      ><span class="fz14 pl5">转移就业</span></span
                    >
                    <span class="c2577E3 fz14 pl10 fbold">
                      <!-- {{
                      cityTotalList?.[j.code]?.stationTotal
                        ? cityTotalList?.[j.code]?.stationTotal
                        : null
                    }} -->
                    {{ simplifyNumber(j.transferEmploymentSum) }}
                    </span>
                  </el-col>
                </el-row>
              </router-link>
            </div>
          </el-col>
        </el-row>
        <!-- </el-carousel-item> -->
        <!-- </el-carousel> -->
      </div>
      <!-- 热门地区 -->
      <el-row class="borf3f3f3b mb5">
        <el-col :span="12" class="flex align_item">
          <span class="fbold borb3FFCD1E lh35 fz24 c2577E3 pl10 pr10"
            >省外热门城市</span
          >
        </el-col>
        <el-col :span="12" class="lh35">
          <div class="flex justify_right">
            <router-link
              :to="{
                name: 'HotCityList',
                query: { level: 1 }
              }"
            >
              <span class="c999 fz14 textHover"
                >查看更多<i class="el-icon-arrow-right el-icon--right"></i
              ></span>
            </router-link>
          </div>
        </el-col>
      </el-row>
      <div class="cityCarousel mt10">
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="pointer pb20"
            v-for="j in provincesList"
            :key="j.code"
          >
            <div class="borf4f4f4 jobHover br10 ove">
              <router-link
                :to="{
                  name: 'Position',
                  query: { areaCode: j.code }
                }"
                target="_blank"
              >
                <div class="footerwh relative">
                  <img :src="j.imgUrl" width="282" height="196" />
                  <span class="address absolute cfff">
                    <i class="iconfont icon-dizhi fz22"></i>
                    <span class="fz18">{{ j.name }}</span>
                  </span>
                </div>
                <el-row class="lh40">
                  <el-col :span="12">
                    <span class="c333 pl10"
                      ><i
                        class="iconfont cccc icon-Id fz20 relative"
                        style="top:3px;"
                      ></i
                      ><span class="fz14 pl5">职位</span></span
                    >
                    <span class="c2577E3 fz14 pl10 fbold">{{simplifyNumber(j.positionNum) }}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="c333"
                      ><i class="iconfont icon-gangweiguanli cccc fz16"></i
                      ><span class="fz14 pl5">岗位</span></span
                    >
                    <span class="c2577E3 fz14 pl10 fbold">{{ simplifyNumber(j.postNum) }}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="c333 pl10"
                      ><i
                        class="cccc el-icon-user-solid fz20 relative"
                        style="top:3px;"
                      ></i
                      ><span class="fz14 pl5">访问</span></span
                    >
                    <span class="c2577E3 fz14 pl10 fbold">{{simplifyNumber(j.visitSum) }}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="c333"
                      ><i class="iconfont icon-renyuanchurushenqing-zhuanyi-01 cccc fz20"></i
                      ><span class="fz14 pl5">转移就业</span></span
                    >
                    <span class="c2577E3 fz14 pl10 fbold">{{ simplifyNumber(j.transferEmploymentSum) }}</span>
                  </el-col>
                </el-row>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { simplifyNumber } from '@/utils'
import '@/assets/font/iconfont.css'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { staticsByJobCityCode } from '@/api/position'
import { getListHotArea } from '@/api/area'
export default defineComponent({
  name: 'Footer',
  setup (props, context) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    /* 获取职位统计 */
    const cityList = ref([[]])
    const cityTotalList = ref(null)
    const provincesList = ref(null)
    const provincesTotalList = ref(null)
    /* 获取列表职位 */
    const getHotCity = async (level) => {
      const resp = await getListHotArea({ isInProvince: level, level: 2 })
      if (resp) {
        if (level === 'F') {
          provincesList.value = resp
          if (provincesList.value.length > 8) {
            provincesList.value = provincesList.value.slice(0, 8)
          }
          // const provincesCodeList = resp.map(i => { return i.code })
          // const provincesData = { jobCityCodes: provincesCodeList }
          // provincesTotalList.value = await staticsByJobCityCode(provincesData)
        } else if (level === 'T') {
          cityList.value = resp
          if (cityList.value.length > 8) {
            cityList.value = cityList.value.slice(0, 8)
          }
          console.log(cityList.value)
          // const cityCodeList = resp.map(i => { return i.code })
          // const cityData = { jobCityCodes: cityCodeList }
          // cityTotalList.value = await staticsByJobCityCode(cityData)
        }
      }
    }
    onMounted(() => {
      getHotCity('F')
      getHotCity('T')
    })
    return {
      simplifyNumber,
      cityTotalList,
      cityList,
      provincesList,
      provincesTotalList
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footerwh {
  width: 282px;
  height: 196px;
  overflow: hidden;
  .address {
    bottom: 10px;
    left: 10px;
  }
}
.cityCarousel {
  ::v-deep .el-carousel__arrow {
    background: rgba(242, 166, 61, 0.8);
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
}
</style>
