import { post, get } from '../service'
import { InfoListDetail } from './data.d'
import { PaginationParams, PaginationResult, HtmlContentResult } from '../data.d'
import axios from 'axios'
const baseUrl = '/app/consultInfo'

interface InfoListParams{
  title?: string,
  type?: string,
}
/**
 * 获取资讯列表
 * @param params
 * @returns
 */
export async function infoList (params: PaginationParams<InfoListParams>): Promise<PaginationResult<InfoListDetail>> {
  const url = `${baseUrl}/list`
  return post<PaginationResult<InfoListDetail>>(url, { ...params })
}

interface GetInfoDetailParams{
  id:number
}
/**
 * 获取资讯详情
 * @param params
 * @returns
 */
export async function getInfoDetail (params: GetInfoDetailParams): Promise<HtmlContentResult<InfoListDetail>> {
  const url = `${baseUrl}/detail`
  const detail = await post<InfoListDetail>(url, { ...params })
  let htmlContent = ''
  try {
    htmlContent = (await axios.get(detail.content)).data
  } catch {
  }
  return { ...detail, htmlContent }
}
