<template>
  <div class="hg100vh">
    <el-row class="hgb100">
      <el-col :span="5" class="bf8f8f8">
        <div class="flex justify_center mt50">
          <img
            v-if="userInfo?.avatar"
            style="width: 80px; height: 80px; border-radius: 100px"
            class="boxshow10"
            :src="`${userInfo?.avatar}`"
          />
          <img
            v-else
            style="width: 80px; height: 80px; border-radius: 100px"
            class="boxshow10"
            src="../assets/avatar/avatar01.png"
          />
        </div>
        <h4 class="f_center pt20 lh30 c333">{{ userInfo?.nickName }}</h4>
        <p class="f_center c999 fz14">
          <i class="el-icon-phone-outline"></i>{{ userInfo?.mobile }}
        </p>
        <div class="fz12 cfff f_center perfectBtn pointer" @click="goResume">
          完善信息
        </div>
        <el-divider></el-divider>
        <div class="lh50 pl20 pointer borderf8l2" :class="[item.id === active ? 'bfff borderF2A63D' : 'cf8f8f8']" v-for="item in navData" :key="item.id" @click="activeNumClick(item)">
          <i class="iconfont fz18" :class="[item.icon,item.id === active ? 'c2577E3' : 'cBFC2C9']"></i>
          <span class="fz16" :class="[item.id === active ? 'c2577E3' : 'c333']">&nbsp;&nbsp;{{item.name}}</span>
        </div>
        <el-divider></el-divider>
        <div class="flex justify_center pt60">
          <el-button size="mini" class="c666" @click="goOut">退出登录</el-button>
        </div>
      </el-col>
      <el-col :span="19" class="bfff">
        <CollectionRecord :nowActive="activeCode"></CollectionRecord>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineComponent, ref, toRef, watch } from 'vue'
import { listResumePost } from '@/api/resume'
import CollectionRecord from './collectionRecord.vue'
export default defineComponent({
  name: 'PersonalCenter',
  components: { CollectionRecord },
  props: {
    activeNum: null
  },
  setup (props, context) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const userInfo = ref(store.state.user?.user)

    /* 当前点击 */
    const navData = ref([
      {
        id: 1,
        name: '已投递',
        icon: 'icon-icon_fasong',
        code: 'hasBeenCast'
      },
      {
        id: 2,
        name: '面试',
        icon: 'icon-icon_wode',
        code: 'interview'

      },
      {
        id: 3,
        name: '感兴趣',
        icon: 'icon-icon_shoucang',
        code: 'collection'

      },
      {
        id: 4,
        name: '推荐职位',
        icon: 'icon-icon_bianji',
        code: 'recommended'
      }
    ])
    const active = ref(1)
    const activeCode = ref('hasBeenCast')
    watch(props, (newValue) => {
      if (newValue.activeNum === 4) {
        activeCode.value = 'recommended'
      }
      active.value = newValue.activeNum
    }, { deep: true, immediate: true })

    const activeNumClick = (item) => {
      active.value = item.id
      activeCode.value = item.code
    }
    const goOut = () => {
      store.dispatch('user/logout')
    }
    /* 跳转完善 */
    const goResume = () => {
      router.push({ name: 'PersonalResume' })
      context.emit('colseResume')
    }
    return {
      userInfo,
      navData,
      active,
      activeCode,
      activeNumClick,
      goOut,
      goResume
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.perfectBtn {
  width: 90px;
  line-height: 30px;
  margin: 10px auto;
  background-image: linear-gradient(274deg, #ee8031, #e6a551);
  box-shadow: 0 10px 10px #fce3d1;
  border-radius: 50px;
  left: calc(50% - 80px);
}
.borderf8l2{border-left: 2px solid #f8f8f8;}
.borderF2A63D{border-left: 2px solid #F2A63D;}
.cf8f8f8{color: #f8f8f8;}
.cBFC2C9 {
  color: #bfc2c9;
}
</style>
