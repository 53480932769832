import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { getToken } from '@/utils/auth'
import store from './store'
import ElementPlus from 'element-plus'
import formCreate from '@form-create/element-ui'
import 'dayjs/locale/zh-cn'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import 'element-plus/lib/theme-chalk/index.css'
import './assets/style/element-plus-00D0D3/newIndex.css'
import 'animate.css'
import 'hover.css'
import './assets/style/font/iconfont.css'
import './assets/style/style.scss'
import EducationTurn from './components/educationTurn.vue'
import PositionImg from './components/positionImg.vue'
import MoneyFormatting from './components/moneyFormatting.vue'

import * as echarts from 'echarts'

router.beforeEach((to, from) => {
  store.dispatch('user/getIpAnalysis')
  if (getToken()) {
    store.dispatch('user/getUser')
    store.dispatch('user/getListDesiredIndustry')
  }
  // 而不是去检查每条路由记录
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !getToken()) {
    // 如果没有，则重定向到登录页面
    return {
      path: '/login',
      // 保存我们所在的位置，以便以后再来
      query: { redirect: to.fullPath }
    }
  }
  document.documentElement.scrollTop = 0
})
const app = createApp(App)
app.use(store)
app.use(router)
app.use(formCreate)
app.config.globalProperties.$echarts = echarts
app.use(ElementPlus, { locale: zhCn })
app.component('PositionImg', PositionImg)
app.component('EducationTurn', EducationTurn)
app.component('MoneyFormatting', MoneyFormatting)
app.mount('#app')
// createApp(App).use(store).use(router).use(formCreate).use(ElementPlus, { locale: zhCn }).component('PositionImg', PositionImg).component('EducationTurn', EducationTurn).component('MoneyFormatting', MoneyFormatting).mount('#app')
