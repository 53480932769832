import request from '@/utils/request'

const BaseUrl = '/app/interview'

/* 新增面试邀请 */
export function addInterview (data) {
  return request({
    url: `${BaseUrl}/addInterview`,
    method: 'post',
    data: data
  })
}
/* 编辑面试邀请 */
export function editInterview (data) {
  return request({
    url: `${BaseUrl}/editInterview`,
    method: 'post',
    data: data
  })
}
/* 公司查询面试邀请列表 */
export function listCompanyInterviewInfo (data) {
  return request({
    url: `${BaseUrl}/listCompanyInterviewInfo`,
    method: 'post',
    data: data
  })
}
/* 个人查询面试邀请 */
export function listMemberInterviewInfo (data) {
  return request({
    url: `${BaseUrl}/listMemberInterviewInfo`,
    method: 'post',
    data: data
  })
}
