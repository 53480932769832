
import { defineComponent, ref } from 'vue'
import AreaSelector, { AreaNode } from '@/components/AreaSelector.vue'
export default defineComponent({
  components: {
    AreaSelector
  },
  setup () {
    const value = ref<string>('530304000000')
    const handleAreaSelected = (area: AreaNode) => {
      console.log(area.code, ':', area.name)
    }
    return { value, handleAreaSelected }
  }
})
