
import LimitedRecruitment from '@/components/limitedRecruitment.vue'
import TopBoard from '@/components/TopBoard.vue'
import HeaderNav from '@/components/headerNav.vue'
import Footers from '@/components/footer.vue'
import { useRoute } from 'vue-router'
import { addBrowseTime } from '@/api/basic'
import { computed, defineComponent, onMounted, ref, watch, toRaw } from 'vue'
export default defineComponent({
  components: {
    TopBoard,
    HeaderNav,
    Footers,
    LimitedRecruitment
  },
  setup (props, context) {
    const route = useRoute()
    const routepath = ref('')
    onMounted(async () => {
      await addBrowseTime({ pageName: 'main' })
    })

    watch(() => route, (newValue, oldValue) => {
      console.log(toRaw(route), 111111111111111)
      console.log(newValue, '新的路由')
      console.log(oldValue, '旧的路由')
    })
    return {
      route,
      routepath
    }
  }
})
