import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/home.vue'
// import Home from '../views/home/homeOld.vue'
import Company from '../views/company.vue'
import News from '../views/news.vue'
import Login from '../views/login/index.vue'
import UserLogin from '../views/login/userLogin.vue'
import Test from '../views/Test.vue'
import { positionRouter } from './modules/position'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/userLogin',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: { requiresAuth: false }
  },
  {
    path: '/companyDetail',
    name: 'CompanyDetail',
    component: () => import('../views/companyDetail.vue')
  },
  {
    path: '/humanResources',
    name: 'HumanResources',
    component: () => import('../views/humanResources.vue')
  },
  {
    path: '/exampleCity',
    name: 'ExampleCity',
    component: () => import('../views/exampleCity/index.vue')
  },
  {
    path: '/exampleCityDetail',
    name: 'ExampleCityDetail',
    component: () => import('../views/exampleCity/detail.vue')
  },
  {
    path: '/information',
    props: (route) => { return { ...route.query, ...route.params } },
    name: 'Information',
    component: () => import('../views/information.vue')
  },
  {
    path: '/informationDetail',
    name: 'InformationDetail',
    component: () => import('../views/informationDetail.vue')
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('../views/form.vue')
  },
  {
    path: '/collegeStudents',
    name: 'CollegeStudents',
    component: () => import('../views/collegeStudents.vue')
  },
  {
    // path: '/position/:positionIds',
    path: '/positionDetail',
    name: 'PositionDetail',
    props: (route) => { return { ...route.query, ...route.params } },
    component: () => import('../views/positionDetail.vue')
  },
  {
    path: '/personalResume',
    name: 'PersonalResume',
    component: () => import('../views/personalResume/index.vue')
    // meta: { requiresAuth:  }
  },
  {
    path: '/addCompany',
    name: 'AddCompany',
    props: (route) => { return { ...route.query, ...route.params } },
    component: () => import('../views/companyManagement/addCompany.vue')
  },
  {
    path: '/liveList',
    name: 'LiveList',
    component: () => import('../views/liveList.vue')
  },
  {
    path: '/timeRecruitment',
    name: 'timeRecruitment',
    component: () => import('../views/timeRecruitment.vue')
  },
  {
    path: '/liveRoom',
    name: 'LiveRoom',
    component: () => import('../views/liveRoom.vue')
  },
  {
    path: '/onlineRecruitment',
    name: 'OnlineRecruitment',
    component: () => import('../views/onlineRecruitment.vue')
  },
  {
    path: '/offline',
    name: 'Offline',
    component: () => import('../views/offline.vue')
  },
  {
    path: '/offline/:offlineId',
    name: 'OfflineDetail',
    props: true,
    component: () => import('../views/offlineDetail.vue')
  },
  {
    path: '/training',
    name: 'Training',
    component: () => import('../views/training.vue')
  },
  {
    path: '/training/:trainingId',
    name: 'TrainingDetail',
    props: true,
    component: () => import('../views/trainingDetail.vue')
  },
  {
    path: '/enterpriseManagement',
    name: 'EnterpriseManagement',
    component: () => import('../views/enterpriseManagement/index.vue'),
    children: [
      {
        path: 'default',
        name: 'Default',
        component: () => import('../views/enterpriseManagement/default.vue')
      },
      {
        path: 'positionCenter',
        name: 'PositionCenter',
        component: () => import('../views/enterpriseManagement/positionCenter.vue')
      },
      {
        path: 'resumeCenter',
        name: 'ResumeCenter',
        component: () => import('../views/enterpriseManagement/resumeCenter.vue')
      },
      {
        path: 'enterpriseInfo',
        name: 'EnterpriseInfo',
        component: () => import('../views/enterpriseManagement/enterpriseInfo.vue')
      },
      {
        path: 'serveEnterprise',
        name: 'ServeEnterprise',
        component: () => import('../views/enterpriseManagement/serveEnterprise.vue')
      },
      {
        path: 'findTalent',
        name: 'FindTalent',
        component: () => import('../views/enterpriseManagement/findTalent.vue')
      },
      {
        path: 'talentManagement',
        name: 'TalentManagement',
        component: () => import('../views/enterpriseManagement/talentManagement.vue')
      },
      {
        path: 'live',
        name: 'Live',
        component: () => import('../views/enterpriseManagement/live.vue')
      }
    ]
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  positionRouter,
  {
    path: '/joinWe',
    name: 'JoinWe',
    component: () => import('../views/joinWe.vue')
  },
  {
    path: '/hotCityList',
    name: 'HotCityList',
    props: (route) => { return { ...route.query, ...route.params } },
    component: () => import('../views/home/hotCityList.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/feedback.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  routes,
  // mode: 'history',
  history: createWebHistory()
})

export default router
