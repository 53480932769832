import { AxiosRequestConfig } from 'axios'
import { post } from '../service'
import { AreaInfo, AreaInfoWithParents, IpAnalysisParams, IpAnalysisResult, ListAreaByNameParams, ListAreaParams, ListAreaHotParams, ListAreaHotResult } from './data'

const baseUrl = '/basic'

/**
 * 获取地区树
 * @param params
 * @returns
 */
export function listArea (params: ListAreaParams, config: AxiosRequestConfig = { retApiRes: true }): Promise<AreaInfo[]> {
  const url = `${baseUrl}/listArea`
  return post<AreaInfo[]>(url, { ...params }, config)
}

/**
 * IP定位
 * @param params
 * @returns
 */
export function ipAnalysis (params: IpAnalysisParams, config: AxiosRequestConfig = { retApiRes: true }): Promise<IpAnalysisResult> {
  const url = `${baseUrl}/ipAnalysis`
  return post<IpAnalysisResult>(url, { ...params }, config)
}

/**
 * 更具名称获取
 * @param params
 * @returns
 */
export function listAreaByName (params: ListAreaByNameParams): Promise<AreaInfoWithParents[]> {
  const url = `${baseUrl}/listAreaByName`
  return post<AreaInfoWithParents[]>(url, { ...params })
}
/* 获取热门城市 */
export function getListHotArea (params:ListAreaHotParams): Promise<ListAreaHotResult[]> {
  const url = '/app/hotArea/listHotArea'
  return post<ListAreaHotResult[]>(url, { ...params })
}
