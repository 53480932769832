<template>
  <div class="inline_block">
    <span v-if="!education">不限学历</span>
    <span v-if="education === 1">小学</span>
    <span v-if="education === 3">初中</span>
    <span v-if="education === 5">普通高中</span>
    <span v-if="education === 7">技工学校</span>
    <span v-if="education === 9">职业高中</span>
    <span v-if="education === 11">中等专科</span>
    <span v-if="education === 13">大学专科</span>
    <span v-if="education === 15">大学本科</span>
    <span v-if="education === 17">硕士研究生</span>
    <span v-if="education === 19">博士研究生</span>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  name: 'EducationTurn',
  props: {
    eduType: null
  },
  setup (props, context) {
    const education = ref(null)
    watch(props, (newValue) => {
      education.value = Number(newValue.eduType)
    }, { deep: true, immediate: true })
    return {
      education
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
