<template>
  <div>
    <el-affix :offset="99">
      <div class="pd20 bfff">
        <!-- boxshow10 -->
        <div class="contentWarp ove positionSearch lh40 pl10 b2577E3">
          <el-row class="br6">
            <el-col :span="7" class="pd5 pt10">
              <el-input
                size="medium"
                type="text"
                v-model="name"
                placeholder="请输入企业名称"
              ></el-input>
            </el-col>
            <el-col :span="5" class="pd5 pt10">
              <el-cascader
                size="medium"
                style="width:235px;"
                v-model="industryName"
                :options="desiredIndustryOptions"
                :show-all-levels="false"
                placeholder="行业信息"
                clearable
                filterable
                :props="{
                  label: 'name',
                  value: 'name',
                  children: 'childList',
                  emitPath: false,
                }"
              ></el-cascader>
            </el-col>
            <el-col :span="4" class="pd5 pt10">
              <el-select size="medium" v-model="typeid" clearable placeholder="公司性质">
                <el-option
                  v-for="item in propertyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" class="pd5 pt10">
              <el-checkbox v-model="checkedTF" size="medium" class="c999"
                >人力资源公司</el-checkbox
              >
            </el-col>
            <el-col :span="3">
              <div
                class="fz18 b2577E3 f_center cfff srarchBtn wdb100 lh60 hgb100 pointer"
                @click="getCompanyEs"
              >
                <i class="el-icon-search fz20"></i> 搜索
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-affix>
    <!-- 企业 -->
    <div class="contentWarp ove pt20" v-loading="loading">
      <el-row :gutter="20">
        <!-- <el-col :span="8" v-for="item in companyList" :key="item">
          <router-link
            :to="{
              name: 'CompanyDetail',
              query: { id: `${item.id}` },
            }"
          >
            <div class="pd15 bfff mb10 jobHover">
              <el-row>
                <el-col class="ove">
                  <div style="width: 480px; height: 140px; overflow: hidden">
                    <el-image
                      v-if="item.pictureIds"
                      :src="`${item.pictureIds}`"
                      style="width: 70%"
                      lazy
                    />
                    <el-image
                      v-else
                      :src="
                        require(`../assets/company/${Math.ceil(
                          Math.random() * 6
                        )}.jpg`).default
                      "
                      lazy
                      style="width: 70%"
                    />
                  </div>
                </el-col>
                <el-col style="height: 75px">
                  <h4 class="c555 lh35">{{ item.name }}</h4>
                  <p class="fz12 c666 lh25">{{ item.industryName }}</p>
                  <div class="mb5">
                    <el-tag v-if="item.typeid" type="warning" size="mini">{{
                      item.typeid
                    }}</el-tag>
                    <el-tag
                      size="mini"
                      class="ml10"
                      v-if="item.isHumanResources === 'T'"
                      >人力资源</el-tag
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
          </router-link>
        </el-col> -->
        <el-col :span="8" v-for="item in companyList" :key="item">
          <router-link
            :to="{
              name: 'CompanyDetail',
              query: { id: `${item.id}` },
            }"
          >
            <el-row class="pd10 borf4f4f4 mb20 jobHover">
              <el-col :span="5">
                  <img v-if="item.logo" class="block br10 borf3f3f3" width="72" height="72" :src="`${item.logo}`"/>
                  <img v-if="!item.logo" class="block br10 borf3f3f3" width="72" height="72" src="@/assets/company/logo.png"/>
              </el-col>
              <el-col :span="19" class="pl5">
                <el-row>
                  <el-col :span="18">
                    <p class="fz16 c666 lh30 textover">{{item.name}}</p>
                  </el-col>
                  <el-col :span="6" class="flex justify_center align_item">
                    <span class="br50 c2577E3 fz12 borFB7B0A pl5 pr5" v-if="item. isAuth !=='T'">已认证</span>
                  </el-col>
                </el-row>
                <p class="fz12 c999">
                  <!-- <span class="pr5">{{item.city}}</span> -->
                  <span class="mr10">{{item.industryName}}</span>
                  <span class="mr10">{{item.typeid}}</span>
                  <span class="mr10">
                    <span v-if="item.minNumber">{{item.minNumber}}</span>
                    <span v-if="item.maxNumber">{{`-${item.maxNumber}人`}}</span>
                   </span>
                </p>
              </el-col>
              <el-col :span="24" class="mt5" v-if="item.welfare" style="border-top:1px dashed #f4f4f4">
                <p class="c999 lh30 pl10 wordbreak fz12">
                  <el-tag v-for="j in (item.welfare.split(','))" :key="j" size="mini"  type="info" class="mr5">
                    {{ j }}
                  </el-tag>
                </p>
              </el-col>
            </el-row>
          </router-link>
        </el-col>
      </el-row>
      <div class="flex justify_center mb20">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { listIndustryTree } from '@/api/basic'
import { listCompanyEs } from '@/api/company'
export default defineComponent({
  name: 'Company',
  components: { },
  setup (props, context) {
    const loading = ref(false)
    const route = useRoute()
    console.log('route', route.params)
    const propertyList = ref([
      { value: '国企', label: '国企' },
      { value: '外企', label: '外企' },
      { value: '合资', label: '合资' },
      { value: '民营', label: '民营' },
      { value: '上市公司', label: '上市公司' },
      { value: '股份制企业', label: '股份制企业' },
      { value: '事业单位', label: '事业单位' },
      { value: '其他', label: '其他' }
    ])
    const companyInfo = reactive({
      page: 1,
      pageSize: 15,
      total: null,
      checkedTF: false, // 是否人力资源
      name: null, // 企业名称
      industryName: null, // 行业
      typeid: null // 公司性质
    })
    /* 加载行业 */
    const desiredIndustryOptions = ref(null) // 行业数据
    listIndustryTree().then((resp) => {
      if (resp.code === '0') {
        resp.results.forEach(item => {
          delete item.childList
          // item.childList.forEach(j => {
          //   if (j.childList?.length <= 0) {
          //     delete j.childList
          //   }
          // })
        })
        desiredIndustryOptions.value = resp.results
      }
    })
    /* 获取企业列表 */
    const companyList = ref([])
    const getCompanyEs = async () => {
      const data = {
        name: companyInfo.name,
        page: companyInfo.page,
        pageSize: companyInfo.pageSize,
        isHumanResources: companyInfo.checkedTF ? 'T' : null,
        typeid: companyInfo.typeid,
        certificateStatus: 'A',
        industryName: companyInfo.industryName
      }
      loading.value = true
      const resp = await listCompanyEs(data)
      loading.value = false
      if (resp.code === '0') {
        resp.results.records.forEach((item) => {
          item.pictureIds = item.pictureIds?.split(',')[0]
        })
        companyList.value = resp.results.records
        companyInfo.total = Number(resp.results.total)
      }
    }
    getCompanyEs()
    /* 分页大小点击 */
    const handleSizeChange = (newSize) => {
      companyInfo.pageSize = newSize
      getCompanyEs()
    }
    // 分页赋值
    const handleCurrentChange = (val) => {
      companyInfo.page = val
      getCompanyEs()
    }
    onMounted(() => {})
    return {
      ...toRefs(companyInfo),
      desiredIndustryOptions,
      propertyList,
      handleSizeChange,
      handleCurrentChange,
      getCompanyEs,
      companyList,
      loading
    }
  }
})
</script>
<style lang="scss" scoped>
.positionSearch {
  ::v-deep .el-input__inner {
    border: 0px;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #333;
    border-color: #333;
  }
  ::v-deep .el-checkbox__label {
    color: #333;
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #333;
  }
  .searchBor {
    border-left: 1px solid #dadfe6;
  }
  .srarchBtn {
    background: #f98f16;
  }
}
</style>
