<template>
  <div class="loginBox">
    <div class="loginWarp bfff">
       <!-- animate__animated animate__zoomInDown -->
      <h3 class="f_center pb10">登录 / 注册</h3>
      <el-form ref="ruleFormRef" :rules="loginFormRules" :model="loginForm">
        <el-form-item prop="mobile">
          <el-input
            placeholder="请输入手机号码"
            v-model="loginForm.mobile"
            class="hvr-underline-from-center"
             @keyup.enter="submitForm"
          ></el-input>
        </el-form-item>
         <!-- prop="verificationCode" -->
        <el-form-item prop="verificationCode">
          <el-row>
            <el-col :span="16">
              <el-input
                placeholder="请输入验证码"
                v-model="loginForm.verificationCode"
                 @keyup.enter="submitForm"
                class="hvr-underline-from-center"
              ></el-input>
            </el-col>
            <el-col :span="8">
              <el-button
                type="text"
                :disabled="coolTime > 0"
                @click="getCode"
                >{{
                  coolTime > 0 ? "(" + coolTime + "秒)" : "获取动态码"
                }}</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
          <!-- <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            v-model="loginForm.password"
            class="hvr-underline-from-center"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <p>
            <el-checkbox v-model="agreeChecked" @keyup.enter="submitForm"></el-checkbox>
            <span> 接受</span>
            <span class="pointer" @click="rulesDetailTF = true"
              >《用户信息隐私权规定及法律声明服务协议》</span
            >
          </p>
        </el-form-item>
        <el-form-item class="flex justify_center">
          <el-button type="primary" class="loginBtn" @click="submitForm"
            >一键登录 / 注册</el-button
          >
        </el-form-item>
        <div style="cursor: pointer" @click="wechatLogin">微信登录</div>
      </el-form>
    </div>
    <!-- 企业认证规则详情 -->
      <AgreementHtml v-model="rulesDetailTF" agreement-type="U"></AgreementHtml>
  </div>
</template>
<script>
import { defineComponent, reactive, unref, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { sendVerificationCode } from '@/api/user'
import AgreementHtml from '@/components/agreementHtml.vue'
import { debounce } from '@/utils'
export default defineComponent({
  components: { AgreementHtml },
  setup (props) {
    const route = useRoute()
    console.log(route.params)
    const store = useStore()
    const backPath = ref(route.params?.path)
    const agreeChecked = ref(false)
    console.log('backPath', backPath)
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1(3|4|5|6|7|8)\d{9}$/
        // const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        if (reg.test(value.trim())) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    const ruleFormRef = ref(null)
    const loginForm = reactive({
      mobile: null,
      verificationCode: null,
      password: null
    })
    const loginFormRules = {
      mobile: [{ required: true, validator: checkPhone, trigger: 'blur' }],
      verificationCode: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { min: 4, max: 4, message: '请输入四位验证码', trigger: 'blur' }
      ],
      password: [{ required: true, message: '请输入密码', trigger: 'blur' }]

    }
    const coolTime = ref(0) // 验证码倒计时
    const getCode = debounce(async () => {
      if (!loginForm.mobile || loginForm.mobile.trim().length !== 11) {
        ElMessage.error({
          message: '请输入正确的手机号码',
          type: 'error'
        })
      } else {
        const data = {
          mobile: loginForm.mobile.trim()
        }
        const resp = await sendVerificationCode(data)
        if (resp.code === '0') {
          ElMessage.success({
            message: '发送成功',
            type: 'success'
          })
          coolTime.value = 60
          let timer = {}
          if (timer) {
            clearInterval(timer)
          }
          timer = setInterval(() => {
            coolTime.value -= 1
            if (coolTime.value <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          ElMessage.warning(resp.message)
        }
      }
    }, 500)
    const submitForm = async () => {
      const form = unref(ruleFormRef)
      if (!form) return
      await form.validate()
      const { mobile, verificationCode, password } = loginForm
      const data = {
        mobile: mobile,
        verificationCode: verificationCode,
        password: password,
        path: backPath.value
      }
      if (!agreeChecked.value) {
        ElMessage.warning('请您同意协议')
      } else {
        store.dispatch('user/login', data)
      }
    }

    // 微信登录
    const codeValue = ref('')
    const wechatLogin = async () => {
      const appid = 'wx98b30c05b848cd26'
      const redirectURL = encodeURIComponent('https://www.qihjob.com/')
      const scope = 'snsapi_login'
      const state = 'state'
      const method = 'qrcode'
      const mode = 'message'
      const loginurl = `https://auth.qihjob.com/wx_auth.html?appid=${appid}&method=${method}&redirectURL=${redirectURL}&scope=${scope}&state=${state}&mode=${mode}`
      const newWindow = window.open(loginurl, '', 'width=800,height=600,top=100px,left=20px')
      window.onmessage = function (event) {
        console.log(event)
        if (event.type === 'message') {
          newWindow.close()
          codeValue.value = event.data.code
          console.log('aaa')
          const data = {
            configCode: 'recruitment_wc_login',
            thirdPartCode: codeValue.value,
            path: backPath.value
          }
          store.dispatch('user/wechatLogin', data)
        }
      }
    }

    /* 注册法律信息弹窗 */
    const rulesDetailTF = ref(false)
    return {
      loginForm,
      loginFormRules,
      submitForm,
      ruleFormRef,
      coolTime,
      backPath,
      getCode,
      agreeChecked,
      rulesDetailTF,
      wechatLogin,
      codeValue
    }
  }
})
</script>
<style lang="scss" scoped>
.loginBox {
  width: 100%;
  height: calc(100vh);
  position: relative;
  padding: 40px 200px 0 0;
  background: url("../../assets/loginbg.jpg") center center no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  z-index: 1;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: inherit;
    /* filter: blur(5px); */
    z-index: 0;
  }
  .loginWarp {
    position: absolute;
    right: 200px;
    top: 200px;
    width: 340px;
    padding: 40px;
    overflow: auto;
    box-shadow: 0  0 14px #f1f1f1;
    z-index: 1;
    .loginBtn {
      margin-top: 20px;
      width: 360px;
      background: #F2A63D;
      border: 0;
    }
  }
  ::v-deep .el-input__inner {
    border-radius: 0;
    border: 0px;
    border-bottom: 1.5px solid #ccc;
  }
  ::v-deep .hvr-underline-from-center:before {
    z-index: 3;
    background: #F2A63D;
    height: 2px;
  }
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
