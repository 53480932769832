<template>
  <div class="pb10">
    <div class="lh50 pl20 boxshow10">
      <h4 v-if="nowCode === 'hasBeenCast'">已投递</h4>
      <h4 v-if="nowCode === 'collection'">收藏</h4>
      <h4 v-if="nowCode === 'recommended'">推荐职位</h4>
      <h4 v-if="nowCode === 'interview'">面试</h4>
    </div>
    <el-scrollbar height="600px" v-if="collectionPostList.length > 0">
      <el-row class="pd10">
        <el-col
          v-for="item in collectionPostList"
          :key="item.id"
          class="c666 borf3f3f3b fz14 pb10 pt10"
        >
          <router-link
            :to="{
              name: 'PositionDetail',
              query: { positionIds: `${item.positionId ? item.positionId : item.id}` },
            }"
            target="_blank"
          >
            <el-row>
              <el-col :span="12">
                <h4 class="c2577E3 lh30">{{ item.positionName }}</h4>
                <p class="fz12 c666 pb10">
                  <span class="pl5">{{ item.experience }}</span>
                  <EducationTurn
                    class="pl5"
                    :eduType="item.education"
                  ></EducationTurn>
                  <span v-if="!item.result" class="pl5">{{ item.recruitingNum }}人</span>
                </p>
                <p v-if="!item.industryName">
                  <el-tag size="mini">{{ item.industryName }}</el-tag>
                </p>
              </el-col>
              <el-col :span="12">
                <div class="fbold cff3535">
                  <MoneyFormatting :minSalary="item.minSalary" :maxSalary="item.maxSalary" :salaryUnit="item.salaryUnit" moneyType="Y"></MoneyFormatting>
                </div>
                <p class="lh20 c999 fz12">{{item.companyName || item.servantCompanyName}}</p>
                 <div v-if="item.isCancel ==='T'" class="pt5">
                  <el-tag type="danger" size="small">该职位已取消投递</el-tag>
                </div>
              </el-col>
              <el-col>
                <div v-if="item.result" class="c666 lh30">
                  <span v-if="item.result === 'P'">面试结果：<el-tag size="mini" type="success">面试通过</el-tag></span>
                  <span v-if="item.result === 'F'">面试结果：<el-tag size="mini" type="danger">面试未通过</el-tag></span>
                  <span v-if="item.result === 'W'">面试结果：<el-tag size="mini" type="info">待定</el-tag></span>
              </div>
              <div class="c666 lh30" v-if="item.contacts">
                面试联系人：{{item.contacts}}
                </div>
              <div class="c666 lh30" v-if="item.contactsPhone">
                联系人电话：{{item.contactsPhone}}
              </div>
              <div class="c666 lh30" v-if="item.interviewTime">
                面试时间：{{parseTime(item.interviewTime)}}
              </div>
              <div class="c666 lh30"  v-if="item.interviewAddress">
                面试地点：{{item.interviewAddress}}
              </div>
              </el-col>
            </el-row>
          </router-link>
        </el-col>
      </el-row>
    </el-scrollbar>
     <el-empty v-if="collectionPostList.length <= 0" description="暂无内容"></el-empty>
    <div class="flex justify_center align_item">
      <el-pagination
        background
        :current-page="page"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { listPositionCollectionByMember, recommend } from '@/api/position'
import { listMemberResumePost } from '@/api/resume'
import { listMemberInterviewInfo } from '@/api/interview'
import { parseTime } from '@/utils'

export default defineComponent({
  name: 'CollectionRecord',
  props: {
    nowActive: null
  },
  setup (props, context) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    // const cityCode = ref(null)
    // const getIpAnalysis = async () => {
    //   const resp = await ipAnalysis({})
    //   if (resp.code === '0' && resp.results) {
    //     cityCode.value = resp.results?.adcode
    //   }
    // }
    // getIpAnalysis()

    const userInfo = ref(store.state.user)
    /* 收藏列表 */
    const searchObj = reactive({
      page: 1,
      pageSize: 10,
      total: 0
    })

    /* 感兴趣 */
    const collectionPostList = ref([])
    const getListPositionCollectionByMember = async () => {
      const data = {
        page: searchObj.page,
        pageSize: searchObj.pageSize
      }
      const resp = await listPositionCollectionByMember(data)
      if (resp.results.records) {
        collectionPostList.value = resp.results.records
        searchObj.total = Number(resp.results.total)
      } else {
        collectionPostList.value = []
      }
    }
    /* 投递记录 */
    const getListResumePost = async () => {
      const data = {
        // memberId: userInfo.value?.user?.id,
        page: searchObj.page,
        pageSize: searchObj.pageSize
      }
      const resp = await listMemberResumePost(data)
      if (resp.results.records) {
        collectionPostList.value = resp.results.records
        searchObj.total = Number(resp.results.total)
      } else {
        collectionPostList.value = []
      }
    }
    /* 推荐职位 */
    const getRecommand = async () => {
      const data = {
        education: userInfo.value?.user?.education,
        page: searchObj.page,
        jobCityCode: userInfo.value?.cityCode,
        pageSize: searchObj.pageSize
      }
      const resp = await recommend(data)
      if (resp.results.records) {
        collectionPostList.value = resp.results.records
        searchObj.total = Number(resp.results.total)
      } else {
        collectionPostList.value = []
      }
    }
    /* 面试记录 */
    const getListMemberInterviewInfo = async () => {
      const data = {
        page: searchObj.page,
        pageSize: searchObj.pageSize
      }
      const resp = await listMemberInterviewInfo(data)
      if (resp.code === '0') {
        if (resp.results.records) {
          collectionPostList.value = resp.results.records
          searchObj.total = Number(resp.results.total)
        } else {
          collectionPostList.value = []
        }
      }
    }
    /* 监听变化 */
    const nowCode = ref('hasBeenCast')
    watch(props, (newValue, oldValue) => {
      console.log(newValue.nowActive)
      nowCode.value = newValue.nowActive
      searchObj.page = 1
      switch (newValue.nowActive) {
        case 'collection':
          getListPositionCollectionByMember()
          break
        case 'hasBeenCast':
          getListResumePost()
          break
        case 'recommended':
          getRecommand()
          break
        case 'interview':
          getListMemberInterviewInfo()
          break
        default:
          break
      }
    }, { immediate: true, deep: true })
    // 分页赋值
    const handleCurrentChange = (val) => {
      searchObj.page = val
      switch (props.nowActive) {
        case 'collection':
          getListPositionCollectionByMember()
          break
        case 'hasBeenCast':
          getListResumePost()
          break
        case 'recommended':
          getRecommand()
          break
        case 'interview':
          getListMemberInterviewInfo()
          break
        default:
          break
      }
    }
    onMounted(() => {
      // getListResumePost()
    })
    return {
      collectionPostList,
      parseTime,
      ...toRefs(searchObj),
      handleCurrentChange,
      nowCode
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
