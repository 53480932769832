<template>
<div>
    <div class="about">
    <h1>咨询</h1>
  </div>
</div>
</template>
<script>
import { defineComponent, reactive, ref, toRefs } from 'vue'
export default defineComponent({
  name: 'Company',
  components: { },
  setup () {

  }
})
</script>
