<template>
  <div class="hg100vh">
    <el-row class="hgb100">
      <el-col :span="8" class="bf8f8f8">
        <div
          class="lh50 pl20 pointer borderf8l2"
          :class="[item.id === activeNum ? 'bfff borderF2A63D' : 'cf8f8f8']"
          v-for="item in navData"
          :key="item.id"
          @click="activeNumClick(item)"
        >
          <i
            class="iconfont fz18"
            :class="[item.icon, item.id === activeNum ? 'c2577E3' : 'cBFC2C9']"
          ></i>
          <span
            class="fz16"
            :class="[item.id === activeNum ? 'c2577E3' : 'c333']"
            >&nbsp;&nbsp;{{ item.name }}</span
          >
        </div>
      </el-col>
      <el-col :span="16" class="bfff">
        <div class="pb10">
          <div class="lh50 pl20 boxshow10">
            <h4 v-if="activeCode === 'certification'">
              <el-row type="flex" justify="space-between">
                <el-col :span="12">企业</el-col>
                <el-col :span="12">
                  <router-link :to="{name: 'AddCompany', query: { attestationType: 'G' }}">
                    <div class="perfectBtn f_center cfff fz14" size="small">
                      新增
                    </div>
                  </router-link>
                </el-col>
              </el-row>
            </h4>
            <h4 v-if="activeCode === 'humanResources'">
              <el-row type="flex" justify="space-between">
                <el-col :span="12">人力资源机构</el-col>
                <el-col :span="12">
                  <router-link :to="{name: 'AddCompany', query: { attestationType: 'H' }}">
                    <div class="perfectBtn f_center cfff fz14" size="small">
                      新增
                    </div>
                  </router-link>
                </el-col>
              </el-row>
            </h4>
            <h4 v-if="activeCode === 'record'">认证记录</h4>
          </div>
          <el-scrollbar height="600px">
            <el-row class="pd10"  v-if="activeCode === 'record'">
              <el-col
                v-for="item in companyAttestation"
                :key="item.id"
                class="c666 borf3f3f3b fz14 pb10 pt10"
              >
                <span class="pr10">{{ item.companyName }}</span>
                <el-button
                  v-if="item.auditStatus === 'T' && activeCode !== 'record'"
                  type="success"
                  size="mini"
                  @click="goEnterprise(item)"
                  >进入企业</el-button
                >
                <span v-if="activeCode === 'record'">
                  <el-tag
                    class="mr10"
                    size="mini"
                    v-if="item.auditStatus === 'W'"
                    >待审</el-tag
                  >
                  <el-tag
                    class="mr10"
                    size="mini"
                    v-if="item.auditStatus === 'T'"
                    type="success"
                    >通过</el-tag
                  >
                  <el-tag
                    class="mr10"
                    size="mini"
                    v-if="item.auditStatus === 'N'"
                    type="info"
                    >未通过</el-tag
                  >
                </span>
              </el-col>
            </el-row>
            <!-- 企业列表 -->
            <el-row class="pd10"  v-else>
              <el-col
                v-for="item in companyList"
                :key="item.id"
                class="c666 borf3f3f3b fz14 pb10 pt10"
              >
                <span class="pr10">{{ item.companyName }}</span>
                <el-button
                  type="success"
                  size="mini"
                  @click="goEnterprise(item)"
                  >进入企业</el-button
                >
              </el-col>
            </el-row>
            <el-empty v-if="companyAttestation.length <= 0 && activeCode === 'record'" description="暂无内容"></el-empty>
            <el-empty v-if="companyList.length <= 0 && activeCode !== 'record'" description="暂无内容"></el-empty>
          </el-scrollbar>
        </div>
      </el-col>
    </el-row>
  </div>

  <!-- <div class="pb50">
    <div class="ml10 mr10">
      <div class="ove-auto pb10 mt10 relative" style="height: 70vh">
        <el-tabs v-model="activeName">
          <el-tab-pane label="企业" name="certification">
            <el-row>
              <el-col
                class="lh40 c666 fz14 borf3f3f3b"
                v-for="item in companyAttestation"
                :key="item.id"
              >
                <div v-if="item.auditStatus === 'T'">
                  <span class="pr10">{{ item.companyName }}</span>
                  <el-button
                    type="success"
                    size="mini"
                    @click="goEnterprise(item)"
                    >进入企业</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="人力资源机构" name="humanResources">
            <el-row>
              <el-col
                class="lh40 c666 fz14 borf3f3f3b"
                v-for="item in companyAttestation"
                :key="item.id"
              >
                <div v-if="item.auditStatus === 'T'">
                  <span class="pr10">{{ item.companyName }}</span>
                  <el-button
                    type="success"
                    size="mini"
                    @click="goEnterprise(item)"
                    >进入企业</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="认证记录" name="record">
            <el-row>
              <el-col
                v-for="item in companyAttestation"
                :key="item.id"
                class="lh40 c666 borf3f3f3b fz14"
              >
                <span class="pr10">{{ item.companyName }}</span>
                <el-tag
                  class="mr10"
                  size="small"
                  v-if="item.auditStatus === 'W'"
                  >待审</el-tag
                >
                <el-tag
                  class="mr10"
                  size="small"
                  v-if="item.auditStatus === 'T'"
                  type="success"
                  >通过</el-tag
                >
                <el-tag
                  class="mr10"
                  size="small"
                  v-if="item.auditStatus === 'N'"
                  type="info"
                  >未通过</el-tag
                >
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <el-empty
          description=""
          v-if="companyAttestation.length <= 0"
        ></el-empty>
      </div>
    </div>
  </div> -->
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineComponent, ref, watch } from 'vue'
import {
  getToken,
  setCompanyId,
  setCompanyName,
  setIsHumanResources
} from '@/utils/auth'
import { parseTime } from '@/utils'
import { listCompanyAttestation, adminListCompany } from '@/api/company'

export default defineComponent({
  name: 'Enterprise',
  setup (props, context) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const userInfo = ref(store.state.user)

    /* 当前点击 */
    const navData = ref([
      {
        id: 1,
        name: '企业',
        icon: 'icon-building',
        code: 'certification'
      },
      {
        id: 2,
        name: '人力资源机构',
        icon: 'icon-men',
        code: 'humanResources'
      },
      {
        id: 3,
        name: '认证记录',
        icon: 'icon-register',
        code: 'record'
      }
    ])
    const activeNum = ref(1)
    const activeCode = ref('certification')
    const activeNumClick = (item) => {
      activeNum.value = item.id
      activeCode.value = item.code
    }
    /* 获取认证列表 */
    const companyAttestation = ref([])
    const getListCompanyAttestation = async () => {
      const data = {
        page: 1,
        pageSize: 30,
        memberId: userInfo.value?.user?.id
      }
      const resp = await listCompanyAttestation(data)
      if (resp.code === '0') {
        companyAttestation.value = resp.results.records
      }
    }
    /* 获取企业列表 */
    const companyList = ref([])
    const getAdminListCompany = async (isHumanResources) => {
      const data = {
        page: 1,
        pageSize: 30,
        isHumanResources: isHumanResources
      }
      const resp = await adminListCompany(data)
      if (resp.code === '0') {
        companyList.value = resp.results.records
      }
    }
    setTimeout(() => {
      if (getToken()) {
        getAdminListCompany('F')
      }
    }, 200)
    watch(
      activeCode,
      (newValue, oldValue) => {
        if (newValue === 'certification') {
          getAdminListCompany('F')
        } else if (newValue === 'humanResources') {
          getAdminListCompany('T')
        } else {
          getListCompanyAttestation()
        }
      },
      { deep: true, immediate: true }
    )
    /* 去企业管理中心 */
    const goEnterprise = (item) => {
      setCompanyId(item.companyId)
      setCompanyName(item.companyName)
      setIsHumanResources(item.attestationType === 'H' ? 'T' : 'F')
      // const routeData = router.resolve({
      //   path: '/enterpriseManagement/default'
      // })
      // window.open(routeData.href, '_blank')
      router.push({ path: '/enterpriseManagement/enterpriseInfo' })
    }
    return {
      goEnterprise,
      activeCode,
      activeNum,
      activeNumClick,
      companyAttestation,
      navData,
      companyList
    }
  }
})
</script>

<style scoped lang="scss">
.perfectBtn {
  width: 90px;
  line-height: 30px;
  margin: 10px auto;
  background-image: linear-gradient(274deg, #ee8031, #e6a551);
  box-shadow: 0 10px 10px #fce3d1;
  border-radius: 50px;
  left: calc(50% - 80px);
}
.borderf8l2 {
  border-left: 2px solid #f8f8f8;
}
.borderF2A63D {
  border-left: 2px solid #f2a63d;
}
.cf8f8f8 {
  color: #f8f8f8;
}
.cBFC2C9 {
  color: #bfc2c9;
}
</style>
