import request from '@/utils/request'
const BaseUrl = '/app/resume'
/* 简历 */

/* 获取简历列表 */
export function listResume (data) {
  return request({
    url: `${BaseUrl}/listResume`,
    method: 'post',
    data: data
  })
}
/* 获取个人简历详细信息 */
export function getResume (data) {
  return request({
    url: `${BaseUrl}/getResume`,
    method: 'post',
    data: data
  })
}
/* 获取个人简历详细信息 */
export function getResumeByCompany (data) {
  return request({
    url: `${BaseUrl}/getResumeByCompany`,
    method: 'post',
    data: data
  })
}
/* 简历搜索 */
export function searchResume (data) {
  return request({
    url: `${BaseUrl}/searchResume`,
    method: 'post',
    data: data
  })
}
/* 保存个人优势 */
export function saveResume (data) {
  return request({
    url: `${BaseUrl}/saveResume`,
    method: 'post',
    data: data
  })
}
/* 保存期望职位 */
export function savePosition (data) {
  return request({
    url: `${BaseUrl}/savePosition`,
    method: 'post',
    data: data
  })
}
/* 删除期望职位 */
export function deletePosition (data) {
  return request({
    url: `${BaseUrl}/deletePosition`,
    method: 'post',
    data: data
  })
}

/* 保存工作经历 */
export function saveWork (data) {
  return request({
    url: `${BaseUrl}/saveWork`,
    method: 'post',
    data: data
  })
}
/* 删除工作经历 */
export function deleteWork (data) {
  return request({
    url: `${BaseUrl}/deleteWork`,
    method: 'post',
    data: data
  })
}
/* 保存教育经历 */
export function saveEducation (data) {
  return request({
    url: `${BaseUrl}/saveEducation`,
    method: 'post',
    data: data
  })
}
/* 删除教育经历 */
export function deleteEducation (data) {
  return request({
    url: `${BaseUrl}/deleteEducation`,
    method: 'post',
    data: data
  })
}
/* 保存资格证书 */
export function saveCertificate (data) {
  return request({
    url: `${BaseUrl}/saveCertificate`,
    method: 'post',
    data: data
  })
}
/* 删除资格证书 */
export function deleteCertificate (data) {
  return request({
    url: `${BaseUrl}/deleteCertificate`,
    method: 'post',
    data: data
  })
}
/* 投递简历 */
export function addResumePost (data) {
  return request({
    url: `${BaseUrl}/addResumePost`,
    method: 'post',
    data: data
  })
}
/* 获取个人投递记录 */
export function listMemberResumePost (data) {
  return request({
    url: `${BaseUrl}/listMemberResumePost`,
    method: 'post',
    data: data
  })
}
/* 获取简历投递列表 */
export function listResumePostForCompany (data) {
  return request({
    url: `${BaseUrl}/listResumePostForCompany`,
    method: 'post',
    data: data
  })
}
/* 获取简历投递详情 */
export function getResumePostByPositionId (data) {
  return request({
    url: `${BaseUrl}/getResumePostByPositionId `,
    method: 'post',
    data: data
  })
}
/* 检查是否索要过简历 */
export function checkResumeDemand (data) {
  return request({
    url: `${BaseUrl}/checkReumeDemand `,
    method: 'post',
    data: data
  })
}
/* 索要简历 */
export function resumeDemand (data) {
  return request({
    url: `${BaseUrl}/resumeDemand `,
    method: 'post',
    data: data
  })
}
/* 用户取消投递简历 */
export function memberCancelPost (data) {
  return request({
    url: `${BaseUrl}/memberCancelPost `,
    method: 'post',
    data: data
  })
}
