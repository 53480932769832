import { post } from '../api/service'
const baseUrl = '/app/advMaterial'
export interface AdvListDetail {
  content: string,
  id: number,
  imageUrl: string,
  modifyDate: string,
  pageView: number,
  publishDate: string,
  publisher: string,
  title: string,
  top: boolean,
  type: string
}

interface AdvListParams{
  advCode?: string,
  platform?: string, // 显示平台（PC; H5; MINIAPP; APP）
}
/**
 * 获取资讯列表
 * @param params
 * @returns
 */
export async function advList (params:AdvListParams): Promise<AdvListDetail> {
  const url = `${baseUrl}/list`
  return post<AdvListDetail>(url, { ...params })
}
