import request from '@/utils/request'
const BaseUrl = '/app/company'

/* 获取公司列表 */
export function listCompany (data) {
  return request({
    url: `${BaseUrl}/listCompany`,
    method: 'post',
    data: data
  })
}
/* 获取人力资源列表 */
export function ListSource (data) {
  return request({
    url: '/app/hotCompany/listHotCompany',
    method: 'post',
    data: data
  })
}
/* 获取公司列表ES */
export function listCompanyEs (data) {
  return request({
    url: `${BaseUrl}/listCompanyEs`,
    method: 'post',
    data: data
  })
}
/* 新建公司 */
export function addCompany (data) {
  return request({
    url: `${BaseUrl}/addCompany`,
    method: 'post',
    data: data
  })
}
/* 编辑公司 */
export function editCompany (data) {
  return request({
    url: `${BaseUrl}/editCompany`,
    method: 'post',
    data: data
  })
}
/* 获取企业详情 */
export function getDetail (data) {
  return request({
    url: `${BaseUrl}/getDetail`,
    method: 'post',
    data: data
  })
}
/* 认证公司 */
export function addCompanyAttestation (data) {
  return request({
    url: `${BaseUrl}/addCompanyAttestation`,
    method: 'post',
    data: data,
    retApiRes: true
  })
}
/* 获取企业账号列表 */
export function listCompanyAccount (data) {
  return request({
    url: `${BaseUrl}/listCompanyAccount`,
    method: 'post',
    data: data
  })
}
/* 获取的公司审核信息列表 */
export function listCompanyAttestation (data) {
  return request({
    url: `${BaseUrl}/listCompanyAttestation`,
    method: 'post',
    data: data
  })
}
/* 人力资源公司添加用人单位 */
export function addCompanyHumanResource (data) {
  return request({
    url: `${BaseUrl}/addCompanyHumanResource`,
    method: 'post',
    data: data
  })
}

/* 人力资源公司删除用人单位关联 */
export function deleteCompanyHumanResource (data) {
  return request({
    url: `${BaseUrl}/deleteCompanyHumanResource`,
    method: 'post',
    data: data
  })
}

/* 人力资源公司编辑用人单位信息 */
export function editCompanyHumanResource (data) {
  return request({
    url: `${BaseUrl}/editCompanyHumanResource`,
    method: 'post',
    data: data
  })
}

/* 分页查询人力资源公司用户单位信息 */
export function listCompanyHumanResource (data) {
  return request({
    url: `${BaseUrl}/listCompanyHumanResource`,
    method: 'post',
    data: data
  })
}
/* 添加简历收藏 */
export function collectResume (data) {
  return request({
    url: `${BaseUrl}/collectResume`,
    method: 'post',
    data: data
  })
}
/* 删除简历收藏 */
export function collectResumeDelete (data) {
  return request({
    url: `${BaseUrl}/collectResumeDelete`,
    method: 'post',
    data: data
  })
}
/* 简历收藏列表 */
export function collectResumeList (data) {
  return request({
    url: `${BaseUrl}/collectResumeList`,
    method: 'post',
    data: data
  })
}
/* 推荐简历 */
export function recommendResume (data) {
  return request({
    url: `${BaseUrl}/recommendResume`,
    method: 'post',
    data: data
  })
}

/* 查询人才管理列表 */
export function listCollectResume (data) {
  return request({
    url: `${BaseUrl}/listCollectResume`,
    method: 'post',
    data: data
  })
}
/* 编辑人才管理信息 */
export function editCollectResume (data) {
  return request({
    url: `${BaseUrl}/editCollectResume`,
    method: 'post',
    data: data
  })
}
/* 查询公司与会员状态 */
export function getCompanyMemberRelationShip (data) {
  return request({
    url: `${BaseUrl}/getCompanyMemberRelationShip`,
    method: 'post',
    data: data
  })
}
/* 管理员获取企业列表 */
export function adminListCompany (data) {
  return request({
    url: `${BaseUrl}/adminListCompany`,
    method: 'post',
    data: data
  })
}
