<template>
  <div :class="[widthHeight === 60 ? 'wh60': 'wh100']" class="ove">
    <el-image v-if="industrys.slice(0,3) == '001'" :src="require('../assets/positionIcon/农林牧渔业.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '010'" :src="require('../assets/positionIcon/其他.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '002'" :src="require('../assets/positionIcon/采矿业.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '003'" :src="require('../assets/positionIcon/制造业.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '004'" :src="require('../assets/positionIcon/电力燃气.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '005'" :src="require('../assets/positionIcon/建筑业.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '006'" :src="require('../assets/positionIcon/仓储物流业.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '007'" :src="require('../assets/positionIcon/零售业.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '008'" :src="require('../assets/positionIcon/餐饮业.jpg').default"></el-image>
    <el-image v-if="industrys.slice(0,3) == '009'" :src="require('../assets/positionIcon/居住服务.jpg').default"></el-image>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  name: 'PositionImg',
  props: {
    industryCode: null,
    wh: null
  },
  setup (props, context) {
    const industrys = ref('010000')
    const widthHeight = ref(60)
    watch(props, (newValue) => {
      industrys.value = newValue.industryCode
      widthHeight.value = Number(newValue.wh)
    }, { deep: true, immediate: true })
    return {
      industrys,
      widthHeight
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .wh60{
    width: 60px;
    height: 60px;
  }
  .wh100{
    width: 100px;
    height: 100px;
  }
</style>
