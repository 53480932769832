import { login, logout, getUser, loginWechat, bindWechat } from '@/api/user'
import { listCompany, listCompanyAccount } from '@/api/company'
import { getToken, setToken, removeToken, setDesiredIndustry,setMemberId } from '@/utils/auth'
import router from '@/router'
import { ElMessage, ElLoading } from 'element-plus'
import { listIndustryTree } from '@/api/basic'
import { ipAnalysis } from '@/api/area'

const state = {
  token: getToken(),
  user: {},
  name: '',
  avatar: '',
  companyList: [],
  desiredIndustry: [],
  noCompanyList: [],
  listCompany: [],
  cityCode: null,
  cityCodeList: [],
  authKey: '',
  isBind: false,
  accessToken: '',
  openId: ''
}

const mutations = {
  SET_USER: (state, users) => {
    state.user = users
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },

  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_COMPANY_LIST: (state, list) => {
    state.companyList = list
  },
  SET_DESIRED_INDUSTRY: (state, list) => {
    state.desiredIndustry = list
  },
  SET_NO_COMPANY_LIST: (state, list) => {
    state.noCompanyList = list
  },
  SET_LIST_COMPANY: (state, list) => {
    state.listCompany = list
  },
  SET_CITY_CODE: (state, code) => {
    state.cityCode = code
  },
  SET_CITY_CODE_LIST: (state, data) => {
    state.cityCodeList = data
  },
  SET_IS_BIND: (state, isBind) => {
    state.isBind = isBind
  },
  SET_ASS_TOKEN: (state, accessToken) => {
    state.accessToken = accessToken
  },
  SET_OPEN_ID: (state, openId) => {
    state.openId = openId
  }
}

const actions = {
  // user login
  login ({ commit, dispatch }, userInfo) {
    const { mobile, verificationCode, password, path } = userInfo
    console.log('path', path)
    return new Promise((resolve, reject) => {
      login({ appId: process.env.VUE_APP_APPID, phone: mobile.trim(), verifyCode: verificationCode, password: password }).then(response => {
        if (response.code === '0') {
          console.log('token', response.results.token)
          setToken(response.results.token)
          commit('SET_TOKEN', response.results.token)
          commit('SET_USER', response.results.userInfo)
          router.push(path || '/')
        } else {
          ElMessage.warning(response.message)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 微信登录

  wechatLogin ({ commit, dispatch }, wechatData) {
    const { configCode, thirdPartCode, path } = wechatData
    return new Promise((resolve, reject) => {
      loginWechat({ configCode: configCode, thirdPartCode: thirdPartCode }).then(response => {
        console.log('re', response)
        if (response.code === '0') {
          console.log('token', response.results.token)
          setToken(response.results.token)
          commit('SET_TOKEN', response.results.token)
          commit('SET_USER', response.results.userInfo)
          router.push(path || '/')
        } else if (response.code === '200100') {
          commit('SET_IS_BIND', true)
          commit('SET_ASS_TOKEN', response.results.accessToken)
          commit('SET_OPEN_ID', response.results.openId)
        } else {
          ElMessage.warning(response.message)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 绑定微信
  bindPhone ({ commit, state }, bindData) {
    const { mobile, verificationCode, path, appId } = bindData
    return new Promise((resolve, reject) => {
      // console.log(state.accessToken)
      bindWechat({ phone: mobile, verifyCode: verificationCode, appId: appId, accessToken: state.accessToken, openId: state.openId }).then(response => {
        // console.log('re', response)
        if (response.code === '0') {
          setToken(response.results.token)
          
          commit('SET_TOKEN', response.results.token)
          commit('SET_USER', response.results.userInfo)
          const loadingEl = ElLoading.service({ fullscreen: true, text: '正在跳转请稍等', lock: true })
          setTimeout(() => {
            loadingEl.close()
            router.push(path || '/')
          }, 2000)
        } else if (response.code === '200200') {
          ElMessage.warning(response.message)
          commit('SET_IS_BIND', false)
        } else {
          ElMessage.warning(response.message)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getUser ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUser().then(response => {
        const data = response.results
        setMemberId(response.results.id)
        commit('SET_USER', data)
        resolve(data)
      }).catch(error => {
        if (error.code === '1') {
          ElMessage.warning(error.message)
        }
        reject(error)
      })
    })
  },
  /* 行业数据 */
  getListDesiredIndustry ({ commit, state }) {
    return new Promise((resolve, reject) => {
      listIndustryTree().then((resp) => {
        if (resp.code === '0') {
          resp.results.forEach((item) => {
            item.childList.forEach((j) => {
              if (j.childList?.length <= 0) {
                delete j.childList
              }
            })
          })
          commit('SET_DESIRED_INDUSTRY', resp.results)
        }
        resolve(resp.results)
      }).catch(error => {
        if (error.code === '1') {
          ElMessage.warning(error.message)
        }
        reject(error)
      })
    })
  },
  /* 全部公司数据 */
  getListCompany ({ commit, state }) {
    return new Promise((resolve, reject) => {
      listCompany({ page: 1, pageSize: 20 }).then(response => {
        const data = response.results
        commit('SET_COMPANY_LIST', data.records)
        resolve(data)
      }).catch(error => {
        if (error.code === '1') {
          ElMessage.warning(error.message)
        }
        reject(error)
      })
    })
  },
  /* 未认证公司数据 */
  getNoListCompany ({ commit, state }) {
    return new Promise((resolve, reject) => {
      listCompany({ page: 1, pageSize: 30, certificateStatus: 'U' }).then(response => {
        const data = response.results
        commit('SET_NO_COMPANY_LIST', data.records)
        resolve(data)
      }).catch(error => {
        if (error.code === '1') {
          ElMessage.warning(error.message)
        }
        reject(error)
      })
    })
  },

  /* 本人公司数据 */
  getMeListCompany ({ commit, state }) {
    return new Promise((resolve, reject) => {
      listCompanyAccount({
        memberId: state.user.id,
        status: 'A'
      }).then(response => {
        const data = response.results
        commit('SET_LIST_COMPANY', data)
        resolve(data)
      }).catch(error => {
        if (error.code === '1') {
          ElMessage.warning(error.message)
        }
        reject(error)
      })
    })
  },
  /* ip定位 */
  getIpAnalysis ({ commit, state }) {
    return new Promise((resolve, reject) => {
      ipAnalysis({}).then(response => {
        const data = response.results?.adcode
        const dataList = [
          data.slice(0, 2) + '0000000000',
          data
        ]
        commit('SET_CITY_CODE', data)
        commit('SET_CITY_CODE_LIST', dataList)
        resolve(data)
      }).catch(error => {
        if (error.code === '1') {
          ElMessage.warning(error.message)
        }
        reject(error)
      })
    })
  },
  // user logout
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        ElMessage.success('退出成功')
        commit('SET_TOKEN', null)
        commit('SET_USER', {})
        removeToken()
        router.push('/')
        setTimeout(() => {
          location.reload()
        }, 300)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
