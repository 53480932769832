import axios from 'axios'
import axiosUtils from 'axios/lib/utils'
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store'
import router from '@/router'
import { getToken, removeToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.state.user.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.Authorization = getToken()
      // config.headers.deptId = 1
    }
    /* 忽略空 */
    const ignoreEmpty = config.ignoreEmpty === null || config.ignoreEmpty === undefined ? true : config.ignoreEmpty
    if (typeof config.data === 'object' && !Array.isArray(config.data) && !axiosUtils.isFormData(config.data)) {
      const data = {}
      const originData = config.data || {}
      Object.keys(originData).forEach((key) => {
        const value = originData[key]
        if (value !== null && (!ignoreEmpty || value !== '')) {
          data[key] = value
        }
      })
      config.data = data
    }

    const params = {}
    const originParams = config.params || {}
    Object.keys(originParams).forEach((key) => {
      const value = originParams[key]
      if (value !== null && (!ignoreEmpty || value !== '')) {
        params[key] = value
      }
    })
    config.params = params
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    const retApiRes = response.config.retApiRes ?? true
    if (res.code !== '0' && !getToken()) {
      ElMessage({
        message: res.message,
        type: 'error',
        duration: 2 * 1000
      })
    } else if (res.code === '101') {
      ElMessageBox.alert('云南省求职服务平台，云南启航就业服务平台客服联系方式：400-8061633，服务时间：上午9:00-下午18:00', '平台已存在该企业-更换管理员请联系客服', {
        confirmButtonText: '关闭'
      })
    } else if (res.code !== '0') {
      ElMessage({
        message: res.message,
        type: 'warning',
        duration: 2 * 1000
      })
    } else if (res.code === '4') {
      removeToken()
      router.push('/')
    } else {
      return retApiRes ? res : res.results
    }
  },
  error => {
    console.log('err' + error) // for debug
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
