import { GetJobFairDetailParams, JobFair } from './data.d'
import { post } from '../service'
import { PaginationParams, PaginationResult } from '../data.d'

// import request from '@/utils/request'
const baseUrl = '/app/jobFair'

interface ListJobFairParams {
  searchMethod: string
  query: string
  progress: string
}

/**
 * 获取线下招聘会列表
 * @param {ListJobFairParams} params
 * @returns
 */
export function listJobFair (params: PaginationParams<ListJobFairParams>) {
  const url = `${baseUrl}/listJobFair`
  const { searchMethod, query, progress, page, pageSize } = params
  const apiParams: {title?: string, organizer?: string, address?: string} = {}
  switch (searchMethod) {
    case '1':
      apiParams.title = query
      break
    case '2':
      apiParams.organizer = query
      break
    case '3':
      apiParams.address = query
      break
    default:
      break
  }
  return post<PaginationResult<JobFair>>(url, { ...apiParams, progress, page, pageSize })
}

/**
 * 获取招聘会详情
 * @param params
 * @returns
 */
export async function getJobFairDetail (params: GetJobFairDetailParams) {
  const url = `${baseUrl}/detail`
  return post<JobFair>(url, { ...params })
}

/**
 * 获取统计
 * @returns
 */
export interface StatisticsParams {
  endCount: number
  unStartCount: number
  progressCount: number
}
export async function getStatistics () {
  const url = `${baseUrl}/statistics`
  return post<StatisticsParams>(url, { })
}
