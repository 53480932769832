
import {
  defineComponent,
  ref,
  watch,
  toRefs,
  onMounted
} from 'vue'
import { listArea, listAreaByName } from '@/api/area'
import { AreaInfoWithParents } from '@/api/area/data.d'
import { debounce } from '@/utils'

export interface AreaNode {
  code: string;
  name: string;
  level: number;
  children: AreaNode[];
}

const rootNode: AreaNode = {
  code: '000000000000',
  name: '中国',
  level: 0,
  children: []
}
const areaCache: Record<string, AreaNode> = {
  [rootNode.code]: rootNode
}

const expandAreaCode = (code: string, maxLevel = 2): string[] => {
  const codePath = new Set<string>()
  for (let i = 0; i < maxLevel; i++) {
    let baseCode = ''
    if (i <= 3) {
      baseCode = code.substring(0, i * 2)
    } else {
      baseCode = code.substring(0, i * 3 - 3)
    }
    const paddedCode = baseCode.padEnd(12, '0')
    if (paddedCode === code) {
      break
    }
    codePath.add(baseCode.padEnd(12, '0'))
  }
  console.log('codePath expand', codePath, code)
  return Array.from(codePath)
}

export default defineComponent({
  props: {
    modelValue: {
      type: String
    },
    maxLevel: {
      type: Number,
      default: 1
    },
    clearable: {
      type: Boolean,
      default: true
    },
    selectSize: {
      type: String,
      default: 'mini'
    }
  },
  emits: ['update:modelValue', 'selected'],
  setup (props, context) {
    const { modelValue, maxLevel, selectSize } = toRefs(props)
    console.log('selectSize', selectSize)
    const selectedText = ref<string>('')
    const selectSizes = ref(selectSize)
    const viewingPath = ref<AreaNode[]>([])
    const confirmNode = ref<AreaNode>()
    const currentLevel = ref<number>(0)
    const currentNode = ref<AreaNode>(rootNode)
    const dialogVisible = ref<boolean>(false)

    const loading = ref(false)
    const loadAreaNode = async (parentCode: string) => {
      const cache = areaCache[parentCode]
      if (cache.children.length === 0) {
        loading.value = true
        let res: any
        try {
          res = await listArea({
            code: cache.code !== '000000000000' ? cache.code : null
          })
        } finally {
          loading.value = false
        }

        cache.children = res.results.area.map((r: any) => {
          const node = {
            code: r.code,
            name: r.name,
            level: r.level,
            children: []
          }
          if (!(node.code in areaCache)) {
            areaCache[node.code] = node
          }
          return node
        })
      }
      areaCache[parentCode] = cache
      return cache
    }

    const backfill = async (baseCode?: string) => {
      baseCode = baseCode ?? modelValue.value ?? ''
      const codePath = expandAreaCode(baseCode, maxLevel.value)
      console.log(codePath)
      const nodePath = []
      for (let i = 0; i < codePath.length; i++) {
        const loadedNode = await loadAreaNode(codePath[i])
        nodePath.push(loadedNode)
      }
      viewingPath.value = nodePath.slice(1)
      currentNode.value =
        viewingPath.value[viewingPath.value.length - 1] ?? rootNode
      currentLevel.value = viewingPath.value.length
      confirmNode.value = areaCache[baseCode]
      if (confirmNode.value && maxLevel.value >= viewingPath.value.length) {
        viewingPath.value = [...viewingPath.value, confirmNode.value]
      }
      selectedText.value = confirmNode.value?.name ?? modelValue.value
    }

    onMounted(async () => {
      if (modelValue.value && modelValue.value !== confirmNode.value?.code) {
        // backfill logic
        backfill()
      } else if (!modelValue.value) {
        await loadAreaNode(rootNode.code)
      }
    })

    watch(modelValue, (newValue, oldValue) => {
      if (newValue && newValue !== confirmNode.value?.code) {
        backfill()
      } else if (!newValue) {
        viewingPath.value = []
        currentLevel.value = 0
        selectedText.value = ''
      }
    })

    watch(currentLevel, async (newValue, oldValue) => {
      currentNode.value =
        currentLevel.value === 0
          ? rootNode
          : viewingPath.value[currentLevel.value - 1]
      currentNode.value = await loadAreaNode(currentNode.value.code)
    })

    const inSelectedPath = (code: string) => {
      return viewingPath.value.some((i) => i.code === code)
    }

    const handleAreaSelected = (areaItem: AreaNode) => {
      const index = areaItem.level - 1
      const oldArea = viewingPath.value[index]
      if (oldArea && oldArea.code !== areaItem.code) {
        viewingPath.value = viewingPath.value.slice(0, index)
      }
      viewingPath.value[index] = areaItem
      if (maxLevel.value > areaItem.level) {
        currentLevel.value = areaItem.level
      }
    }

    const handleConfirmSelected = () => {
      confirmNode.value = viewingPath.value[viewingPath.value.length - 1]
      selectedText.value = confirmNode.value?.name
      context.emit('update:modelValue', confirmNode.value?.code)
      context.emit('selected', confirmNode.value)
      dialogVisible.value = false
    }

    const handleClear = () => {
      context.emit('update:modelValue', null)
      context.emit('selected', null)
    }

    /* 建议列表 */
    const suggestionQuery = ref<string>('')
    const handleSuggestionSelected = (item: AreaInfoWithParents) => {
      backfill(item.code)
    }
    const fethSuggetion = async (queryString: string, cb: (res?: any) => void) => {
      let result: AreaInfoWithParents[] = []
      if (!queryString) {
        cb()
        return
      }
      result = await listAreaByName({ name: queryString, level: maxLevel.value })
      cb(result)
    }

    return {
      loading,
      viewingPath,
      selectedText,
      dialogVisible,
      currentLevel,
      currentNode,
      selectSizes,
      inSelectedPath,
      handleAreaSelected,
      handleConfirmSelected,
      handleClear,
      suggestionQuery,
      fethSuggetion,
      handleSuggestionSelected
    }
  }
})
