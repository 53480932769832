/* eslint-disable no-control-regex */
/* eslint-disable camelcase */
/**
 * Created by jiachenpan on 16/11/18.
 */
// import { Message } from 'element-ui'

/* 简化转移就业等大数字 */
/* 活动点击 */
import router from '@/router'

export const simplifyNumber = (num) => {
  return num >= 100000 ? Math.trunc(num / 10000) + '万+' : num
}
export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }

  if (!time) {
    return ''
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * 格式化到时分秒
 * @param time
 * @param cFormat
 * @returns {*}
 */
export function parseData (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate()
  }
  const time_str = format.replace(/{([ymd])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    return value.toString().padStart(2, '0')
  })
  return time_str
}
export function parseStrTime (timeStr, format) {
  let patternStr = format.replace('{y}', '(?<year>\\d{4})')
  patternStr = patternStr.replace('{m}', '(?<month>\\d{2})')
  patternStr = patternStr.replace('{d}', '(?<day>\\d{2})')
  patternStr = patternStr.replace('{H}', '(?<fullHours>\\d{2})')
  patternStr = patternStr.replace('{M}', '(?<fullMinutes>\\d{2})')
  patternStr = patternStr.replace('{s}', '(?<fullSeconds>\\d{2})')
  patternStr = `^${patternStr}$`
  const pattern = new RegExp(patternStr)
  const match = timeStr.match(pattern)
  const groups = match ? match.groups : null
  const year = groups ? parseInt(groups.year || 0) : 0
  const month = groups ? parseInt(groups.month) - 1 : 0
  const day = groups ? parseInt(groups.day || 1) : 0
  const hours = groups ? parseInt(groups.fullHours || 0) : 0
  const minutes = groups ? parseInt(groups.fullMinutes || 0) : 0
  const seconds = groups ? parseInt(groups.fullSeconds || 0) : 0

  return new Date(year, month, day, hours, minutes, seconds)
}

export function formatTime (time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

// 格式化时间
export function getQueryObject (url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * 格式化时间间隔
 * @param delta
 * @returns {string}
 */
export function formatTimeDelta (delta) {
  let restDelta = delta
  let timeNum = 0
  let timeStr = ''
  if (restDelta >= 86400000) {
    timeNum = Math.floor(restDelta / 86400000)
    timeStr += timeNum > 0 ? `${timeNum}天` : ''
    restDelta = restDelta % 86400000
  }

  if (restDelta >= 3600000 && restDelta < 86400000) {
    timeNum = Math.floor(restDelta / 3600000)
    timeStr += timeNum > 0 ? `${timeNum}时` : ''
    restDelta = restDelta % 3600000
  }

  if (restDelta >= 60000 && restDelta < 3600000) {
    timeNum = Math.floor(restDelta / 60000)
    timeStr += timeNum > 0 ? `${timeNum}分` : ''
    restDelta = restDelta % 60000
  }

  if (restDelta >= 1000 && restDelta < 60000) {
    timeNum = Math.floor(restDelta / 1000)
    timeStr += timeNum > 0 ? `${timeNum}秒` : ''
  }

  return timeStr
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getByteLen (val) {
  let len = 0
  for (let i = 0; i < val.length; i++) {
    if (val[i].match(/[^\x00-\xff]/gi) != null) {
      len += 1
    } else {
      len += 0.5
    }
  }
  return Math.floor(len)
}

export function cleanArray (actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

export function param (json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

export function param2Obj (url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  )
}

export function html2Text (val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

export function objectMerge (target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

export function toggleClass (element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

export const pickerOptions = [
  {
    text: '今天',
    onClick (picker) {
      const end = new Date()
      const start = new Date(new Date().toDateString())
      end.setTime(start.getTime())
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: '最近一周',
    onClick (picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: '最近一个月',
    onClick (picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: '最近三个月',
    onClick (picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      picker.$emit('pick', [start, end])
    }
  }
]

export function getTime (type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

export function debounce (func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone (source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

export function uniqueArr (arr) {
  return Array.from(new Set(arr))
}

export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// // 检查手机号是否重复
// export function checkMobile(e) {
//   const mobilePattern = /^1(3|4|5|6|7|8)\d{9}$/
//   if (!mobilePattern.test(e.target.value)) {
//     Message({
//       message: '请输入正确的手机号码',
//       type: 'warning',
//       duration: 2000
//     })
//     return false
//   }
// }

/**
 * 部门/菜单禁用
 * @param deptlist
 * @returns {Array}
 */
export function filterAsyncList (list) {
  const res = []
  list.forEach(item => {
    const tmp = { ...item }
    if (tmp.status && tmp.status === '1') {
      tmp.disabled = true
    } else {
      tmp.disabled = false
    }
    if (tmp.children) {
      tmp.children = filterAsyncList(tmp.children)
    }
    res.push(tmp)
  })
  return res
}

export function scroll (currentY, targetY) {
  // 计算需要移动的距离
  const needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    // window.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      // scroll(_currentY, targetY)
    } else {
      // window.scrollTo(_currentY, targetY)
    }
  }, 1)
}

/**
 * @description: 身份证号校验
 * @param {*}num  身份证号的值
 * @return {*}
 */
export function checkMobileIDNumber (num) {
  if (!num) {
    return
  }
  num = num.toUpperCase()
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
  if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
    return '（身份证号有误）'
  }
  // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
  // 下面分别分析出生日期和校验位
  let len, re
  len = num.length
  if (len === 15) {
    re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/)
    const arrSplit = num.match(re)

    // 检查生日日期是否正确
    const dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
    let bCorrectDay
    bCorrectDay = dtmBirth.getYear() === Number(arrSplit[2]) && dtmBirth.getMonth() + 1 === Number(arrSplit[3]) && dtmBirth.getDate() === Number(arrSplit[4])
    if (!bCorrectDay) {
      return '（出生日期有误）'
    } else {
      // 将15位身份证转成18位
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      let nTemp = 0
      let i
      num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6)
      for (i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i]
      }
      num += arrCh[nTemp % 11]
      return ''
    }
  }
  if (len === 18) {
    re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/)
    const arrSplit = num.match(re)

    // 检查生日日期是否正确
    const dtmBirth = new Date(arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
    let bCorrectDay
    bCorrectDay = dtmBirth.getFullYear() === Number(arrSplit[2]) && dtmBirth.getMonth() + 1 === Number(arrSplit[3]) && dtmBirth.getDate() === Number(arrSplit[4])
    if (!bCorrectDay) {
      return '（出生日期有误）'
    } else {
      // 检验18位身份证的校验码是否正确。
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      let valNum
      const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      let nTemp = 0
      let i
      for (i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i]
      }
      valNum = arrCh[nTemp % 11]
      if (valNum !== num.substr(17, 1)) {
        return '（身份证校验码有误）'
      }
      return ''
    }
  }
  return ''
}

/**
 * @description: 身份证号信息提取
 * @param {*} MyIDCard  身份证号码
 * @param {*} label     对应要return出去的是哪个字段  Hometown 、MyBirthday、MySex、MyDate
 * @return {*}
 */
export function IDCardData (MyIDCard, label) {
  console.log(MyIDCard, label)
  if (MyIDCard) {
    if (label === 'Hometown') {
      // 根据身份证号获取籍贯
      const Hometown = {
        11: '北京',
        12: '天津',
        13: '河北',
        14: '山西',
        15: '内蒙古',
        21: '辽宁',
        22: '吉林',
        23: '黑龙江',
        31: '上海',
        32: '江苏',
        33: '浙江',
        34: '安徽',
        35: '福建',
        36: '江西',
        37: '山东',
        41: '河南',
        42: '湖北',
        43: '湖南',
        44: '广东',
        45: '广西',
        46: '海南',
        50: '重庆',
        51: '四川',
        52: '贵州',
        53: '云南',
        54: '西藏',
        61: '陕西',
        62: '甘肃',
        63: '青海',
        64: '宁夏',
        65: '新疆',
        71: '台湾',
        81: '香港',
        82: '澳门'
      }
      const MyHometown = Hometown[parseInt(MyIDCard.substring(0, 2))]
      return MyHometown
    } else if (label === 'MyBirthday') {
      // 根据身份证号获取出生日期
      const MyBirthday = MyIDCard.substring(6, 10) + '-' + MyIDCard.substring(10, 12) + '-' + MyIDCard.substring(12, 14)
      return MyBirthday
    } else if (label === 'MySex') {
      // 根据身份证号获取性别
      let MySex = ''
      if (parseInt(MyIDCard.substr(16, 1)) % 2 === 1) {
        MySex = '男'
      } else {
        MySex = '女'
      }
      return MySex
    } else if (label === 'MyDate') {
      // 根据身份证号获取年龄
      let MyDate = new Date()
      MyDate = MyDate.getFullYear() - MyIDCard.substring(6, 10)
      return MyDate
    }

    return ''
  } else {
    return ''
  }
}
export function advAnyClick (item) {
  switch (item.sourceType) {
    case 'JF':
      router.push({
        name: 'OfflineDetail', // 线下招聘
        params: { offlineId: item.sourceId }
      })
      break
    case 'PI':
      router.push({
        name: 'PositionDetail', // 职位详情
        query: { positionIds: item.sourceId }
      })
      break
    case 'CI':
      break
    case 'CS':
      router.push({
        name: 'InformationDetail', // 资讯
        query: { id: item.sourceId }
      })
      break
    case 'JT':
      router.push({
        name: 'TrainingDetail', // 培训信息
        params: { trainingId: item.sourceId }
      })
      break
    case 'LR':
      break
    default:
      break
  }
}
