<!--
 * @Author: your name
 * @Date: 2021-08-23 11:47:16
 * @LastEditTime: 2021-12-29 10:44:08
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \employment-front-end-project\src\views\home\components\hotPosition.vue
-->
<template>
  <el-col>
    <div style="min-height:350px;">
      <router-link
    v-for="(item, index) in positionLists" :key="item"
      :to="{
        name: 'PositionDetail',
        query: { positionIds: `${item.id}` },
      }"
      target="_blank"
    >
      <div class="pd5 bfff jobHover mb10">
        <el-row>
          <el-col :span="5" class="pt5">
            <img
              v-if="item.logo"
              class="block br10 borf3f3f3"
              width="48"
              height="48"
              :src="`${(item.logo)}`"
            />
            <img
              v-if="!item.logo"
              class="block br10 borf3f3f3"
              width="48"
              height="48"
              :src="`https://recruitment-prd.obs.cn-south-1.myhuaweicloud.com/app_resource/province_logo/${item.jobCityCode.substring(
                0,
                2
              )}.png`"
            />
          </el-col>
          <el-col :span="19">
            <el-row>
              <el-col>
                <h4 class="f_left fz14 c555 nowrap textover">
                  {{ item.positionName }}
                </h4>
              </el-col>
              <el-col class="lh20">
                <span class="fbold cff3535 fz12">
                  <MoneyFormatting
                    :minSalary="item.minSalary"
                    :maxSalary="item.maxSalary"
                    :salaryUnit="item.salaryUnit"
                    moneyType="Y"
                  ></MoneyFormatting>
                </span>
                <span class="fz12 c999">
                  <EducationTurn
                  class="pl5"
                  :eduType="item?.education"
                ></EducationTurn>
                </span>
              </el-col>
              <el-col class="fz12 lh20 c999">
                <span>{{ item?.city }}</span>
                <span class="pl5">{{ item?.experience }}</span>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </router-link>
    </div>
  </el-col>
</template>
<script>
import { defineComponent, reactive, ref, watch, toRefs } from 'vue'
export default defineComponent({
  name: 'HotPosition',
  props: {
    positionList: null
  },
  components: { },
  setup (props) {
    const positionLists = ref([])

    watch(props, (newValue) => {
      positionLists.value = newValue.positionList
    })
    return {
      positionLists
    }
  }
})
</script>
<style lang="scss" scoped>
  .ranking {
    width: 20px;
    line-height: 20px;
    color: #fff;
  }
</style>
