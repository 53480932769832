
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  name: 'AgreementHtml',
  props: {
    agreementType: {
      type: String,
      default: 'U' // U:用户隐私协议  S:服务协议  C:认证企业    P:职位发布
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const type = ref<string>('U')
    const dialogVisibleTF = ref<boolean>(false)
    watch(props, (newValue) => {
      type.value = newValue.agreementType
      dialogVisibleTF.value = newValue.modelValue
    }, { deep: true, immediate: true })

    /* 关闭回调 */
    const closeHandler = () => {
      context.emit('update:modelValue', false)
    }
    return {
      type,
      dialogVisibleTF,
      closeHandler
    }
  }
})
