<template>
<div class="bf5f5f5">
  <div class="borcccb">
    <div class="contentWarp">
      <div class="contentWarp">
        <!-- <h5 class="lh40 c666">
          云南启航就业服务平台提供诚信、高效、专业、热情的服务
        </h5>
        <p class="lh20 fz12 c999">
          云南启航就业服务平台专业找工作平台!专业平台服务值得信赖!云南本土正规招聘平台，让求职招聘更放心，更便捷！
          专业人才网招聘,免费注册填简历,轻松找工作；海量热门职位招聘
          信息，专业人才网招聘,免费注册填简历,轻松找工作；
        </p> -->
        <h5 class="lh40 c666">热门行业</h5>
        <el-row class="lh30">
          <el-col class="fz14">
            <span
              class="c666 mr20 pointer textHover"
              v-for="(value, key) in sortDict"
              :key="value"
              @click="industryClick(key)"
              >{{ industryFilter(key)
              }}<span class="c2577E3">（{{ value }}）</span>
            </span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
  <div class="contentWarp pb10 mt20">
    <el-row :gutter="10">
    <el-col :span="6" style="border-right:1px solid #ccc">
      <h4 class="lh40">关于我们</h4>
      <p class="fz14 c666 lh25 pr20">云南启航就业服务平台提供诚信、高效、专业、热情的服务
云南启航就业服务平台专业找工作平台!专业平台服务值得信赖!云南本土正规招聘平台，让求职招聘更放心，更便捷！专业人才网招聘,免费注册填简历,轻松找工作；海量热门职位招聘 信息，专业人才网招聘,免费注册填简历,轻松找工作。</p>
    </el-col>
    <el-col :span="1"></el-col>
    <el-col :span="4">
      <h4 class="lh40">网站导航</h4>
      <div class="fz14 lh30">
        <p class="textHover"><router-link class="c666" to="/position">职位</router-link></p>
        <p class="textHover"><router-link class="c666" to="/company">单位</router-link></p>
        <p class="textHover"><router-link class="c666" to="/collegeStudents">大学生专区</router-link></p>
        <p class="textHover"><router-link class="c666" to="/position">限时招聘</router-link></p>
        <p class="textHover"><router-link class="c666" to="/position">随需随聘</router-link></p>
        <p class="textHover"><router-link class="c666" to="/liveList">直播招聘</router-link></p>
        <p class="textHover"><router-link class="c666" to="/training">就业培训</router-link></p>
        <p class="textHover"><router-link class="c666" to="/information">资讯</router-link></p>
      </div>
    </el-col>
    <el-col :span="4">
      <h4 class="lh40">资讯</h4>
      <div class="fz14 lh30">
        <p class="textHover"><router-link class="c666" :to="{
                      path: '/information',
                      query: { typeName: 'POLICIES_REGULATIONS' }
                    }">政策法规</router-link></p>
        <p class="textHover"><router-link class="c666" :to="{
                      path: '/information',
                      query: { typeName: 'INDUSTRY_DYNAMIC' }
                    }">行业资讯</router-link></p>
        <p class="textHover"><router-link class="c666"  :to="{
                      path: '/information',
                      query: { typeName: 'PLATFORM_DYNAMIC' }
                    }">平台动态</router-link></p>
      </div>
    </el-col>
    <el-col :span="4">
      <h4 class="lh40">用户帮助</h4>
      <p class="c666 fz14 lh30 pointer" @click="agreeHandler('P')">用户隐私协议</p>
      <p class="c666 fz14 lh30 pointer" @click="agreeHandler('S')">服务协议</p>
    </el-col>

     <el-col :span="4">
      <h4 class="lh40">联系我们</h4>
      <p class="c666 fz14 lh30 pointer"><router-link class="c666"  :to="{
                      path: '/feedback'
                    }">留言反馈</router-link></p>
    </el-col>
  </el-row>
  </div>
    <AgreementHtml v-model="rulesDetailTF" :agreement-type="rulesType"></AgreementHtml>
</div>
<div style="background:#333;" class="c999 fz12 f_center pt10 pb10">
    <p>
      <span>服务热线：400-8061633（9:00-18:00）</span>
      <span>企业服务邮箱：shspckj@163.com</span>
    </p>
    <p class="lh25">
       <a href="https://beian.miit.gov.cn/" class="c999">备案号：滇ICP备2021004711号-1</a>
       <span class="pl10">运营单位：昆明瀚鹏人力资源信息咨询有限公司</span>
     </p>
</div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineComponent, ref, watch, onMounted } from 'vue'
import AgreementHtml from '@/components/agreementHtml.vue'
import { listPositionStaticsEs } from '@/api/position'

export default defineComponent({
  name: 'Footer',
  components: { AgreementHtml },
  setup (props, context) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const rulesDetailTF = ref(false)
    const rulesType = ref('P')
    const agreeHandler = (value) => {
      rulesType.value = value
      setTimeout(() => { rulesDetailTF.value = true }, 200)
    }
    /* 行业 */
    const sortDict = ref({})
    const getListPositionStaticsEs = async () => {
      const data = {
        page: 1,
        pageSize: 1,
        status: 'UL'
      }
      const resp = await listPositionStaticsEs(data)
      if (resp) {
        sortDict.value = resp.aggs.industryCode
        // const sortValues = Object.values(codeList).sort((a, b) => b - a)
        // for (const i of sortValues) {
        //   for (const key in codeList) {
        //     if (codeList[key] === i) {
        //       sortDict.value[key] = i
        //       delete codeList[key]
        //     }
        //   }
        // }
      }
    }
    /* 过滤行业信息 */
    const industryFilter = code => {
      switch (code) {
        case '001000':
          return '农林牧渔业'
        case '002000':
          return '采矿业'
        case '003000':
          return '制造业'
        case '004000':
          return '电力燃气水'
        case '005000':
          return '建筑业'
        case '006000':
          return '交通仓储物流业'
        case '007000':
          return '批发和零售业'
        case '008000':
          return '住宿和餐饮业'
        case '009000':
          return '居民服务和其他服务业'
        case '010000':
          return '其他行业'
        default:
          break
      }
    }
    /* 热门行业点击 */
    const industryClick = code => {
      setTimeout(() => {
        router.push({
          name: 'Position',
          query: { positionDesiredName: code }
        })
      }, 300)
    }
    onMounted(() => {
      getListPositionStaticsEs()
    })
    return {
      rulesDetailTF,
      rulesType,
      agreeHandler,
      sortDict,
      industryFilter,
      industryClick
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footerwh {
  width: 260px;
  height: 180px;
  overflow: hidden;
  .footText {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 20px;
    border-radius: 50px;
    background: #00d0d3;
    opacity: 0.8;
    text-align: center;
    color: #fff;
  }
}
</style>
