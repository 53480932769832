
<template>
  <div>
    <div class="home ove-auto">
      <!-- bf6f6f8 -->
      <div class="relative">
        <el-carousel
          :interval="5000"
          indicator-position="none"
          ref="carousel"
          class="top-banner"
        >
          <el-carousel-item
            v-for="item in advData"
            :key="item.id"
            @click="advAnyClick(item)"
          >
            <!-- <router-link to="/company"> -->
            <img
              style="width: 100%; height: 595px"
              :src="item.thumbImagePath"
              class="pointer"
            />
          </el-carousel-item>
        </el-carousel>

        <!-- banner活动 -->
        <div class="contentWarp ove absolute bannerActivity">
          <el-row :gutter="10">
            <!-- @click="advAnyClick(item)" -->
            <el-col
              :span="6"
              v-for="item in bannerActivityAdv"
              @click="advAnyClick(item)"
              :key="item.id"
            >
              <div class="ove">
                <img
                  width="290"
                  height="144"
                  class="pointer block br4"
                  :src="item.thumbImagePath"
                />
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 搜索 -->
        <div class="positionSearch ove b2577E3">
          <!-- <AreaSelector/> -->
          <el-row class="lh65">
            <el-col :span="24">
              <el-row :gutter="10">
                <el-col :span="4">
                  <div class="pl10">
                    <AreaSelector
                      v-model="homeSearchValue.areaValue"
                      :max-level="2"
                      :selectSize="'medium'"
                    />
                  </div>
                </el-col>
                <el-col :span="7">
                  <el-input
                    v-model="searchValue"
                    placeholder="请输入职位"
                    clearable
                    size="medium"
                  ></el-input>
                </el-col>
                <el-col :span="5">
                  <el-cascader
                    style="width: 240px"
                    v-model="industryCode"
                    :options="positionOptions"
                    :show-all-levels="false"
                    placeholder="请选择行业"
                    clearable
                    class="fz14"
                    size="medium"
                    filterable
                    :props="{
                      label: 'name',
                      value: 'code',
                      children: 'childList',
                      checkStrictly: false,
                      emitPath: false
                    }"
                  ></el-cascader>
                </el-col>
                <el-col :span="5">
                  <el-select
                    v-model="moneyValue"
                    clearable
                    size="medium"
                    placeholder="薪资要求"
                  >
                    <el-option label="1000元-5000元" value="1-5" />
                    <el-option label="5000元-8000元" value="5-8" />
                    <el-option label="8000元-10000元" value="8-10" />
                    <el-option label="10000元-15000元" value="10-15" />
                    <el-option label="15000元-20000元" value="15-20" />
                    <el-option label="20000元-25000元" value="20-25" />
                    <el-option label="25000元-50000元" value="25-50" />
                    <el-option label="50000元-100000元" value="50-100" />
                  </el-select>
                </el-col>
                <el-col :span="3">
                  <div
                    class="fz18 cfff f_center srarchBtn wdb100 pointer"
                    @click="getListMenu"
                  >
                    <i class="el-icon-search fz20"></i> 搜索
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 政策法规 -->
      <div class="contentWarp bfff mt50">
        <el-row :gutter="20" class="pt10">
          <el-col :span="10">
            <div
              class="policyCarousel ove"
              style="width: 470px"
              v-loading="policyLoading"
            >
              <el-carousel :interval="4000" height="400px" ref="policyCarousel">
                <el-carousel-item
                  v-for="item in informationRightList"
                  class="pointer"
                  :key="item.id"
                  @click="advAnyClick(item)"
                >
                  <!-- <router-link :to="{path:'/informationDetail',query:{id:item.id}}"> -->
                  <img :src="item.thumbImagePath" width="470" height="400" />
                  <p class="titleNmae pl10 fz14 lh30 cfff textover">
                    {{ item.title }}
                  </p>
                  <!-- <span class="description cfff fz12">{{
                    item.description
                  }}</span>-->
                  <!-- </router-link> -->
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
          <el-col :span="14">
            <div v-loading="policyLoading">
              <el-row class="borf3f3f3b mb3 pt10">
                <el-col :span="12" class="flex align_item">
                  <span
                    class="pl5 lh40 borb3FFFFFF pointer pl10 pr10"
                    style=""
                    :class="[
                      activityActive === 1
                        ? 'borb3FFCD1E fz24 c2577E3 fbold'
                        : 'c666 fz20'
                    ]"
                    @click="activityActiveClick(1)"
                    >最新资讯</span
                  >
                  <span
                    class="pl5 pointer borb3FFFFFF lh40 pl20 pr10"
                    :class="[
                      activityActive === 2
                        ? 'borb3FFCD1E fz24 c2577E3 fbold'
                        : 'c666 fz20'
                    ]"
                    @click="activityActiveClick(2)"
                    >最新招聘活动</span
                  >
                </el-col>
                <el-col :span="12" class="flex justify_right align_item">
                  <router-link
                    v-if="activityActive === 1"
                    :to="{
                      path: '/information',
                      query: { typeName: 'POLICIES_REGULATIONS' }
                    }"
                  >
                    <span class="c999 fz14 textHover">
                      查看更多
                      <i class="el-icon-arrow-right el-icon--right"></i>
                    </span>
                  </router-link>
                  <router-link v-if="activityActive === 2" to="/offline">
                    <span class="c999 fz14 textHover">
                      查看更多
                      <i class="el-icon-arrow-right el-icon--right"></i>
                    </span>
                  </router-link>
                </el-col>
              </el-row>
              <div class="bfff mt20">
                <div
                  v-show="activityActive === 1"
                  class="jobHover pd10"
                  v-for="item in informationLeftList"
                  :key="item.id"
                >
                  <router-link
                    :to="{ path: '/informationDetail', query: { id: item.id } }"
                    class="lh25"
                  >
                    <el-row class="c333">
                      <el-col :span="3" class="fz12 c999 flex align_item">
                        <div
                          class="bf5f5f5 f_center"
                          style="width: 75px; height: 84px"
                        >
                          <p
                            style="
                              width: 60px;
                              border-bottom: 2px solid #e1e1e1;
                              margin: 0 auto;
                            "
                            class="c333 fz22 fbold lh40"
                          >
                            {{ parseTime(item.publishDate, "{d}") }}
                          </p>
                          <p class="c999 fz16 pt10">
                            {{ parseTime(item.publishDate, "{y}-{m}") }}
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="21" class="pr10 pl10 textHover">
                        <p class="textover fz16 lh30">{{ item.title }}</p>
                        <div class="fz14 c999 wordbreak">
                          {{ item.summary }}
                        </div>
                      </el-col>
                    </el-row>
                  </router-link>
                </div>
                <div
                  class="jobHover pd10 borf4f4f4 mb10"
                  v-for="item in jobFairs"
                  :key="item.id"
                  v-show="activityActive === 2"
                >
                  <router-link
                    :to="{
                      name: 'OfflineDetail',
                      params: { offlineId: item.id }
                    }"
                  >
                    <el-row>
                      <el-col :span="5">
                        <img
                          :src="item.picture"
                          class="block"
                          width="120"
                          height="78"
                        />
                      </el-col>
                      <el-col :span="19" class="textHover lh25">
                        <p class="lh30 pl5 fz16 c333 textover">
                          {{ item.title }}
                        </p>
                        <div class="flex align_item fz14 c999">
                          <img
                            src="@/assets/svg/detail_icon_time.svg"
                            class="svg mr3"
                            width="20"
                            height="20"
                          />
                          {{ parseTime(item.beginDate, "{y}-{m}-{d} {h}:{s}") }}
                          至
                          {{ parseTime(item.endDate, "{y}-{m}-{d} {h}:{s}") }}
                        </div>
                        <!-- <el-tooltip placement="top">
                          <template #content>{{item.organizer}}</template> -->
                        <div class="flex align_item c999 pr5 textover">
                          <img
                            src="@/assets/svg/detail_icon_send.svg"
                            class="svg mr3"
                            width="20"
                            height="20"
                          />{{ item.organizer }}
                        </div>
                        <!-- </el-tooltip> -->
                      </el-col>
                    </el-row>
                  </router-link>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 统计数据 -->
      <div class="contentWarp bfff borccc borf1f1f1 br3 mt20">
        <div class="pd10 f_center flex space-between">
          <div style="color: #f98f16">
            <count-to
              v-if="
                statistical?.pageBrowseDayTime !== null &&
                  statistical?.pageBrowseDayTime !== undefined
              "
              :start-val="0"
              :end-val="statistical?.pageBrowseDayTime"
              :duration="800"
              class="card-panel-num fbold fz24"
            />
            <div class="fz16">总访问量</div>
          </div>
          <div style="color: #f98f16">
            <count-to
              v-if="
                statistical?.collectSum !== null &&
                  statistical?.collectSum !== undefined
              "
              :start-val="0"
              :end-val="statistical?.collectSum + 100000"
              :duration="800"
              class="card-panel-num fbold fz24"
            />
            <div class="fz16">收藏总数</div>
          </div>
          <div>
            <count-to
              v-if="
                statistical?.postSum !== null &&
                  statistical?.postSum !== undefined
              "
              :start-val="0"
              :end-val="statistical?.postSum + 100000"
              :duration="800"
              class="card-panel-num fbold fz24 c2577E3"
            />
            <div class="fz16 c999">简历投递总数</div>
          </div>
          <div>
            <count-to
              v-if="
                statistical?.positionSum !== null &&
                  statistical?.positionSum !== undefined
              "
              :start-val="0"
              :end-val="statistical.positionSum"
              :duration="800"
              class="card-panel-num fbold fz24 c2577E3"
            />
            <div class="fz16 c999">职位总数</div>
          </div>
          <div>
            <count-to
              v-if="
                statistical?.recentlyPositionSum !== null &&
                  statistical?.recentlyPositionSum !== undefined
              "
              :start-val="0"
              :end-val="statistical?.recentlyPositionSum"
              :duration="800"
              class="card-panel-num fbold fz24 c2577E3"
            />
            <div class="fz16 c999">近期职位数</div>
          </div>
          <div>
            <count-to
              v-if="
                statistical?.recruitingTotal !== null &&
                  statistical?.recruitingTotal !== undefined
              "
              :start-val="0"
              :end-val="statistical?.recruitingTotal"
              :duration="800"
              class="card-panel-num fbold fz24 c2577E3"
            />
            <div class="fz16 c999">岗位总数</div>
          </div>
          <div>
            <count-to
              v-if="
                statistical?.recentlyRecruitingTotal !== null &&
                  statistical?.recentlyRecruitingTotal !== undefined
              "
              :start-val="0"
              :end-val="statistical?.recentlyRecruitingTotal"
              :duration="800"
              class="card-panel-num fbold fz24 c2577E3"
            />
            <div class="fz16 c999">近期岗位数</div>
          </div>
          <div>
            <count-to
              v-if="
                statistical?.countCompany !== null &&
                  statistical?.countCompany !== undefined
              "
              :start-val="0"
              :end-val="statistical?.countCompany"
              :duration="800"
              class="card-panel-num fbold fz24 c2577E3"
            />
            <div class="fz16 c999">企业总数</div>
          </div>
          <div>
            <count-to
              v-if="
                statistical?.recentlyCountCompany !== null &&
                  statistical?.recentlyCountCompany !== undefined
              "
              :start-val="0"
              :end-val="statistical?.recentlyCountCompany"
              :duration="800"
              class="card-panel-num fbold fz24 c2577E3"
            />
            <div class="fz16 c999">近期企业数</div>
          </div>
        </div>
      </div>
      <!-- 主内容 -->
      <div class="affix-container">
        <div class="contentWarp bfff mainBox">
          <!-- 推荐职位 -->
          <el-row v-if="getToken()" class="borf3f3f3b mt20 mb5">
            <el-col :span="12" class="flex align_item">
              <span class="fbold borb3FFCD1E lh35 fz24 c2577E3 pl10 pr10"
                >推荐职位</span
              >
            </el-col>
            <el-col :span="12" class="lh35">
              <div class="flex justify_right">
                <router-link
                  :to="{
                    name: 'Position'
                  }"
                >
                  <span class="c999 fz14 textHover">
                    查看更多
                    <i class="el-icon-arrow-right el-icon--right"></i>
                  </span>
                </router-link>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="getToken()" :gutter="20">
            <el-col v-for="item in recommendedList" :key="item.id" :span="8">
              <router-link
                :to="{
                  name: 'PositionDetail',
                  query: { positionIds: `${item.id}` }
                }"
                target="_blank"
              >
                <div class="borf4f4f4 ove mt20 pd10 jobHover pointer">
                  <el-row class="borf3f3f3b pb10">
                    <el-col :span="12">
                      <h5 class="textover fz16 c333">
                        {{ item?.positionName }}
                      </h5>
                    </el-col>
                    <el-col :span="12" class="flex justify_right">
                      <div class="cff3535 fz14">
                        <MoneyFormatting
                          :minSalary="item?.minSalary"
                          :maxSalary="item?.maxSalary"
                          :salaryUnit="item?.salaryUnit"
                          moneyType="Y"
                        ></MoneyFormatting>
                      </div>
                    </el-col>
                    <el-col class="fz12 lh25 c999">
                      <span>{{ item?.city }}</span>
                      <span class="pl5">{{ item?.experience }}</span>
                      <EducationTurn
                        class="pl5"
                        :eduType="item?.education"
                      ></EducationTurn>
                    </el-col>
                  </el-row>
                  <el-row class="pt5">
                    <el-col :span="4">
                      <img
                        v-if="item.logo"
                        class="block br10 borf3f3f3"
                        width="48"
                        height="48"
                        :src="`${item.logo}`"
                      />
                      <!-- https:// -->
                      <img
                        v-if="!item.logo"
                        class="block br10 borf3f3f3"
                        width="48"
                        height="48"
                        :src="
                          `https://recruitment-prd.obs.cn-south-1.myhuaweicloud.com/app_resource/province_logo/${item.jobCityCode.substring(
                            0,
                            2
                          )}.png`
                        "
                      />
                    </el-col>
                    <el-col :span="18">
                      <p class="fz16 c666 lh30 textover">
                        {{ item.recruitmentCompanyName }}
                      </p>
                      <p class="fz12 c999">
                        <!-- {{item.province}}<span v-if="item.province && item.city">-</span> -->
                        <span class="pr5">{{ item.city }}</span>
                        <span>{{ item.industryName }}</span>
                      </p>
                    </el-col>
                  </el-row>
                </div>
              </router-link>
            </el-col>
          </el-row>
          <!-- 热门职位 -->
          <el-row class="borf3f3f3b mt20 mb5">
            <el-col :span="12" class="flex align_item">
              <span class="fbold borb3FFCD1E lh35 fz24 c2577E3 pl10 pr10"
                >热门职位</span
              >
            </el-col>
            <el-col :span="12" class="lh35">
              <div class="flex justify_right">
                <router-link
                  :to="{
                    name: 'Position'
                  }"
                >
                  <span class="c999 fz14 textHover">
                    查看更多
                    <i class="el-icon-arrow-right el-icon--right"></i>
                  </span>
                </router-link>
              </div>
            </el-col>
          </el-row>
          <!-- <el-row class="lh40 mt10 bf8f8f8 c999 pl20">
            <el-col :span="3" class="pointer" v-if="getToken()" :class="[hotJobValue === '1' ? 'c2577E3' : '']" @click="hotJobHandler('1')">推荐职位</el-col>
            <el-col :span="3" class="pointer" v-for="item in hotJobNameList" :key="item.id" :class="[item.id === hotJobValue ? 'c2577E3' : '']" @click="hotJobHandler(item.id,item.title)">{{item.name}}</el-col>
          </el-row>-->
          <el-row :gutter="20" class="mt20">
            <el-col :span="6">
              <el-row :gutter="20">
                <el-col>
                  <el-row class="lh50 shStyle hotTitBor">
                    <el-col :span="10">
                      <p class="pl10 c333 fz18">上市企业</p>
                    </el-col>
                    <el-col :span="14">
                      <div class="pr10 flex justify_right">
                        <router-link
                          :to="{
                            name: 'Position',
                            query: { propertyValue: '上市公司' }
                          }"
                        >
                          <span class="c333 fz14 pointer">
                            <span class="fbold">
                              {{ countType["上市公司"] }} </span
                            >热招职位<i
                              class="el-icon-arrow-right el-icon--right"
                            ></i
                          ></span>
                        </router-link>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <HotPosition
                  :positionList="servicePositionList.records"
                ></HotPosition>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-row :gutter="20">
                <el-col>
                  <el-row class="lh50 wzStyle hotTitBor">
                    <el-col :span="10">
                      <!-- <h4 class="pl20 cfff">国有企业</h4> -->
                      <p class="pl10 c333 fz18">外资企业</p>
                    </el-col>
                    <el-col :span="14">
                      <div class="pr10 flex justify_right">
                        <router-link
                          :to="{
                            name: 'Position',
                            query: { propertyValue: '外企' }
                          }"
                        >
                          <span class="c333 fz14 pointer">
                            <span class="fbold"> {{ countType["外企"] }} </span
                            >热招职位<i
                              class="el-icon-arrow-right el-icon--right"
                            ></i
                          ></span>
                        </router-link>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <HotPosition
                  :positionList="buildingPositionList.records"
                ></HotPosition>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-row :gutter="20">
                <el-col>
                  <el-row class="lh50 myStyle hotTitBor">
                    <el-col :span="10">
                      <p class="pl10 c333 fz18">民营企业</p>
                      <!-- <h4 class="pl20 cfff">民营类型企业</h4> -->
                    </el-col>
                    <el-col :span="14">
                      <div class="pr10 flex justify_right">
                        <router-link
                          :to="{
                            name: 'Position',
                            query: { propertyValue: '民营' }
                          }"
                        >
                          <span class="c333 fz14 pointer">
                            <span class="fbold">
                              {{ countType["民营公司"] }} </span
                            >热招职位<i
                              class="el-icon-arrow-right el-icon--right"
                            ></i
                          ></span>
                        </router-link>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <HotPosition
                  :positionList="communicationPositionList.records"
                ></HotPosition>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-row :gutter="20">
                <el-col>
                  <el-row class="lh50 myStyle hotTitBor">
                    <el-col :span="12">
                      <p class="pl10 c333 fz18">人力资源公司</p>
                    </el-col>
                    <el-col :span="12">
                      <div class="pr10 flex justify_right">
                        <router-link
                          :to="{
                            name: 'Position',
                            query: { isHuman: 'T' }
                          }"
                        >
                          <span class="c333 fz14 pointer">
                            <span class="fbold"> {{ countType[""] }} </span
                            >热招职位<i
                              class="el-icon-arrow-right el-icon--right"
                            ></i
                          ></span>
                        </router-link>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <HotPosition
                  :positionList="humanResourcesList.records"
                ></HotPosition>
              </el-row>
            </el-col>
          </el-row>
          <!-- 热门企业 -->

          <el-row class="borf3f3f3b mt20 mb5">
            <el-col :span="3" class="flex align_item">
              <!-- <i class="el-icon-mobile-phone"></i> -->
              <span
                id="company"
                @click="companyclick"
                class="fbold borb3FFCD1E lh35 fz24 c2577E3 pl10 pr10 cusor"
                >热门企业</span
              >
            </el-col>
            <el-col :span="6" class="flex align_item">
              <!-- <i class="el-icon-mobile-phone"></i> -->
              <span
                id="source"
                @click="sourceclick"
                ref="companysource"
                class="fbold lh35 fz24 pl10 pr10 cusor"
                >热门人力资源单位</span
              >
            </el-col>
            <el-col :span="12" class="lh35">
              <div class="flex justify_right">
                <router-link :to="{ name: 'Company' }">
                  <span class="c999 fz14 textHover"
                    >查看更多<i class="el-icon-arrow-right el-icon--right"></i
                  ></span>
                </router-link>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt10">
            <el-col
              :span="6"
              v-for="item in listCompanyLine"
              :key="item"
              style="padding: 0"
            >
              <!-- name: `${item.isHumanResources === 'T' ? 'HumanResources':'CompanyDetail'}`, -->
              <div
                class="borf4f4f4 mb10 jobHover flex align_item justify_center"
                style="width: 282px; height: 208px"
              >
                <router-link
                  :to="{
                    name: 'CompanyDetail',
                    query: { id: `${item.companyId}` }
                  }"
                >
                  <img
                    class="block"
                    width="282"
                    height="120"
                    :src="item.thumbImagePath ? item.thumbImagePath : item.logo"
                    lazy
                  />
                  <el-row class="lh40">
                    <el-col :span="12">
                      <span class="c333 pl10"
                        ><i
                          class="iconfont cccc icon-Id fz20 relative"
                          style="top: 3px"
                        ></i
                        ><span class="fz14 pl5">职位</span></span
                      >
                      <span class="c2577E3 fz14 pl10 fbold">{{
                        simplifyNumber(item.positionNum)
                      }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="c333"
                        ><i class="iconfont icon-gangweiguanli cccc fz16"></i
                        ><span class="fz14 pl5">岗位</span></span
                      >
                      <span class="c2577E3 fz14 pl10 fbold">{{
                        simplifyNumber(item.postNum)
                      }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="c333 pl10"
                        ><i
                          class="cccc el-icon-user-solid fz20 relative"
                          style="top: 3px"
                        ></i
                        ><span class="fz14 pl5">访问</span></span
                      >
                      <span class="c2577E3 fz14 pl10 fbold">{{
                        simplifyNumber(item.visitSum)
                      }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="c333"
                        ><i
                          class="
                            iconfont
                            icon-renyuanchurushenqing-zhuanyi-01
                            cccc
                            fz20
                          "
                        ></i
                        ><span class="fz14 pl5">转移就业</span></span
                      >
                      <span class="c2577E3 fz14 pl10 fbold">{{
                        simplifyNumber(item.transferEmploymentSum)
                      }}</span>
                    </el-col>
                  </el-row>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <HotCity></HotCity>
    </div>
    <div class="leftImg ove pointer" style="width: 130px">
      <img
        style="width: 90%"
        @click="advAnyClick(leftAdv?.[0])"
        :src="leftAdv?.[0]?.thumbImagePath"
      />
    </div>
    <div class="rightImg ove pointer" style="width: 130px">
      <!-- <router-link
        :to="{
          name: 'JoinWe',
        }"
      > -->
      <!-- <img style="width: 90%" :src="require('@/assets/right.jpg').default" /> -->
      <img
        style="width: 90%"
        @click="advAnyClick(rightAdv?.[0])"
        :src="rightAdv?.[0]?.thumbImagePath"
      />
      <!-- </router-link> -->
    </div>
  </div>
  <PopupIdentity v-model="popupTF"></PopupIdentity>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
  computed,
  watchEffect,
  onMounted,
  onBeforeMount
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  listPositionEs,
  listPositionStaticsEs,
  recommend
} from '@/api/position'
import { listCompanyEs, ListSource } from '@/api/company'
import { listArea } from '@/api/area'
import { listIndustryTree, getStatics } from '@/api/basic'
import HotCity from '@/components/hotCity'
import AreaSelector from '@/components/AreaSelector'
import PopupIdentity from '@/components/popupIdentity.vue'
import { debounce, parseTime, advAnyClick, simplifyNumber } from '@/utils'
import { getToken } from '@/utils/auth'
import CountTo from '@/components/vue-count-to/src/vue-countTo.vue'
import { advList } from '@/api/advMaterial'
import { infoList } from '@/api/consultInfo'
import HotPosition from './components/hotPosition'
import * as JobFairApi from '@/api/jobfair'

export default defineComponent({
  name: 'Home',
  components: { HotCity, AreaSelector, PopupIdentity, CountTo, HotPosition },
  setup (props, context) {
    const router = useRouter()
    const store = useStore()
    const userInfo = computed(() => store.state.user)
    /* 第一次登录弹窗 */
    const popupTF = ref(false)
    const popupIdentitys = computed(() =>
      window.localStorage.getItem('popupIdentity')
    )
    /* 获取广告 */
    const advData = ref()
    const carousel = ref()
    const getAdvList = async advCode => {
      advData.value = await advList({ advCode: advCode, platform: 'PC' })
      setTimeout(() => {
        carousel.value.setActiveItem(0)
      }, 200)
    }
    const leftAdv = ref()
    const getLeftAdv = async advCode => {
      leftAdv.value = await advList({ advCode: advCode, platform: 'PC' })
    }
    const rightAdv = ref()
    const getrightAdv = async advCode => {
      rightAdv.value = await advList({ advCode: advCode, platform: 'PC' })
    }
    const bannerActivityAdv = ref()
    const getBannerActivityAdv = async advCode => {
      bannerActivityAdv.value = await advList({
        advCode: advCode,
        platform: 'PC'
      })
    }

    watch(
      popupIdentitys,
      newValue => {
        if (!newValue && getToken()) {
          popupTF.value = true
        }
      },
      { deep: true, immediate: true }
    )

    const homeSearchValue = reactive({
      areaValue: null, // 地区内容
      searchValue: null, // 搜索内容
      moneyValue: null, // 薪资要求
      industryCode: null // 行业
    }) // 搜索内容
    /* 加载地区 */
    const cityTrue = ref(false)
    const positionCityValue = ref([])
    watch(
      () => store.state.user.cityCode,
      newValue => {
        if (newValue) {
          homeSearchValue.areaValue = newValue
          positionCityValue.value = [
            newValue?.slice(0, 2) + '0000000000',
            newValue
          ]
          setTimeout(() => {
            cityTrue.value = true
          }, 600)
        }
      },
      { immediate: true }
    )
    const areaProps = ref({
      lazy: true,
      checkStrictly: 'true',
      emitPath: false,
      lazyLoad (node, resolve) {
        const { level } = node
        listArea({ code: node.value }).then(res => {
          const provinceList = res.results.area.map(item => ({
            value: item.code,
            label: item.name,
            leaf: level > 0 // 可控制显示几级
          }))
          resolve && resolve(provinceList)
        })
      }
    })
    const cascaderRef = ref()
    /* 加载行业 */
    const positionOptions = ref(null) // 岗位数据
    listIndustryTree().then(resp => {
      if (resp.code === '0') {
        resp.results.forEach(item => {
          delete item.childList
          // item.childList.forEach(j => {
          //   if (j.childList?.length <= 0) {
          //     delete j.childList
          //   }
          // })
        })
        positionOptions.value = resp.results
      }
    })

    // 岗位数统计
    const positionTotal = ref(0)
    const positionAggs = ref({})
    const countCompany = ref({})
    const countType = ref({})

    const getListPositionStaticsEs = async () => {
      const peopleNum = homeSearchValue.scopeValue?.split('-')
      const moneyNum = homeSearchValue.moneyValue?.split('-')
      const data = {
        // search: homeSearchValue.searchValue,
        // jobCityCode: homeSearchValue.areaValue,
        // positionTypeCode: homeSearchValue.industryCode,
        page: 1,
        pageSize: 1
        // minSalary: moneyNum?.[0],
        // maxSalary: moneyNum?.[1],
        // minNumber: peopleNum?.[0],
        // maxNumber: peopleNum?.[1],
        // education: homeSearchValue.educationalValue,
        // experience: homeSearchValue.experienceValue,
        // typeid: homeSearchValue.propertyValue,
        // status: 'UL'
      }
      const resp = await listPositionStaticsEs(data)
      if (resp) {
        positionTotal.value = resp.total
        countCompany.value = resp.aggs.countCompany
        positionAggs.value = resp.aggs
        countType.value = resp.aggs.typeid
      }
    }

    watch(homeSearchValue, newValue => {
      getListPositionStaticsEs()
    })
    /* 职位列表 */
    const hotJobNameList = ref([
      { id: '2', name: '上市企业', title: '上市公司' },
      { id: '3', name: '外资企业', title: '外企' },
      { id: '4', name: '民营企业', title: '民营' },
      { id: '5', name: '人力资源公司' }
    ])
    const hotJobValue = ref(getToken() ? '1' : '2') // 热门职位点击值
    const typeidName = ref('上市公司')
    const hotJobHandler = (value, title) => {
      typeidName.value = title
      hotJobValue.value = value
    }

    // const positionList = ref([])
    const communicationPositionList = ref([])
    const buildingPositionList = ref([])
    const servicePositionList = ref([])
    const humanResourcesList = ref([])
    const getListPositionEs = async (typeIdNames, isHuman) => {
      const data = {
        page: 1,
        pageSize: 5,
        status: 'UL',
        isSynonym: 'F',
        isHot: 'T',
        orderBy: 'maxSalary',
        orderSequence: 'desc',
        isHumanResources: isHuman ? 'T' : null,
        // positionTypeCode: typeCode || null,
        typeid: !isHuman ? typeIdNames : null
        // jobCityCode: homeSearchValue.areaValue || null
      }
      const resp = await listPositionEs(data)
      if (resp.code === '0') {
        switch (typeIdNames) {
          case '民营':
            communicationPositionList.value = resp.results
            break
          case '外企':
            buildingPositionList.value = resp.results
            break
          case '上市公司':
            servicePositionList.value = resp.results
            break
          case '人力资源':
            humanResourcesList.value = resp.results
            break
          default:
            break
        }
      }
    }
    /* 推荐职位 */
    const recommendedList = ref([])
    const getRecommand = async () => {
      const data = {
        // education: userInfo.value.user?.education,
        page: 1,
        // jobCityCode: userInfo.value.cityCode,
        pageSize: 6
      }
      const resp = await recommend(data)
      if (resp.results.records) {
        recommendedList.value = resp.results.records
      } else {
        recommendedList.value = []
      }
    }
    // watch(hotJobValue, (newValue) => {
    //   if (newValue === '1') {
    //     getRecommand()
    //   } else {
    //     getListPositionEs()
    //   }
    // }, { immediate: true })
    /* 职位地区筛选 */
    // const positionCascaderRef = ref()
    // const positionSelectArea = debounce(value => {
    //   const positionAreaList = []
    //   const positionAreaCodes = positionCascaderRef.value.getCheckedNodes()[0]
    //  ?.data.value
    //   const positionProvinceCode = positionAreaCodes
    //     ? positionAreaCodes?.slice(0, 2) + '0000000000'
    //     : null
    //   const positionCityCode =
    //     positionAreaCodes?.slice(2, 4) > 0
    //       ? positionAreaCodes?.slice(0, 4) + '00000000'
    //       : null
    //   if (positionProvinceCode) {
    //     positionAreaList.push(positionProvinceCode)
    //   }
    //   if (positionCityCode) {
    //     positionAreaList.push(positionCityCode)
    //   }
    //   positionCityValue.value = positionAreaList
    //   setTimeout(() => {
    //     getListPositionEs()
    //   }, 200)
    // })

    const listCompanyLine = ref([])
    const getListCompany = () => {
      ListSource({ isHumanResources: 'F' }).then(res => {
        console.log(res)
        listCompanyLine.value = res.results
      })
      // console.log(listCompanyLine.value, "4555888888888888");
    }

    const aaaa = ref([])
    const getListSource = () => {
      console.log('资源')
      ListSource({
        isHumanResources: 'T'
      }).then(res => {
        console.log(res)
        listCompanyLine.value = res.results
      })
      // console.log(aaaa.value, "4544444444444");
    }
    // 切换
    // const company = ref(null);
    const companyclick = () => {
      getListCompany()
      console.log(document.querySelector('#company'))
      document.querySelector('#company').className =
        'fbold borb3FFCD1E lh35 fz24 c2577E3 pl10 pr10 cusor'
      document.querySelector('#source').className =
        'fbold lh35 fz24 pl10 pr10 cusor'
    }
    const sourceclick = () => {
      getListSource()
      document.querySelector('#company').className =
        'fbold lh35 fz24 pl10 pr10 cusor'
      document.querySelector('#source').className =
        'fbold borb3FFCD1E lh35 fz24 c2577E3 pl10 pr10 cusor'
    }
    /* 搜索跳转 */
    //  value: JSON.stringify(homeSearchValue)
    const getListMenu = () => {
      router.push({
        name: 'Position',
        query: {
          isSynonym: homeSearchValue.isSynonym,
          areaValue: homeSearchValue?.areaValue,
          searchValue: homeSearchValue?.searchValue,
          moneyValue: homeSearchValue.moneyValue,
          industryCode: homeSearchValue.industryCode,
          experienceValue: homeSearchValue.experienceValue,
          educationalValue: homeSearchValue.educationalValue,
          propertyValue: homeSearchValue.propertyValue,
          scopeValue: homeSearchValue.scopeValue
        }
      })
    }
    /* 获取统计 */
    const statistical = ref(null)
    const getStaticsNum = async () => {
      const resp = await getStatics()
      if (resp.code === '0') {
        statistical.value = resp.results
      }
    }

    /* 获取资讯列表和最新活动列表 */
    const informationLeftList = ref([])
    const informationRightList = ref([])

    const policyCarousel = ref()
    const policyLoading = ref(false)
    const getInfoList = async () => {
      policyLoading.value = true
      const resp = await infoList({
        page: 1,
        pageSize: 3,
        type: 'POLICIES_REGULATIONS'
      })
      policyLoading.value = false
      informationLeftList.value = resp.records.slice(0, 5)
    }

    const activityActive = ref(1)
    const activityActiveClick = value => {
      activityActive.value = value
    }
    const jobFairs = ref([])
    const searchJobFairs = async () => {
      const result = await JobFairApi.listJobFair({
        page: 1,
        pageSize: 3
      })
      jobFairs.value = result.records
    }

    const getInformationRightList = async advCode => {
      informationRightList.value = await advList({
        advCode: advCode,
        platform: 'PC'
      })
      setTimeout(() => {
        policyCarousel.value.setActiveItem(0)
      }, 200)
    }

    onMounted(() => {
      getAdvList('BANNNER_INDEX_TOP')
      getLeftAdv('FLOAT_INDEX_LEFT')
      getrightAdv('FLOAT_INDEX_RIGHT')
      getInformationRightList('BANNER_INDEX_ANY')
      getBannerActivityAdv('BANNNER_INDEX_TOP_ACTIVITY')
      getInfoList()
      getStaticsNum()
      getListPositionStaticsEs()
      getListCompany()
      searchJobFairs()
      /* 获取推荐职位 */
      if (getToken()) {
        getRecommand()
      }
      // getListSource();
      getListPositionEs('上市公司', false)
      getListPositionEs('外企', false)
      getListPositionEs('民营', false)
      getListPositionEs('人力资源', true)
    })
    return {
      simplifyNumber,
      companyclick,
      sourceclick,
      aaaa,
      getToken,
      advData,
      parseTime,
      carousel,
      homeSearchValue,
      popupTF,
      ...toRefs(homeSearchValue),
      positionTotal,
      positionAggs,
      getListPositionEs,
      listCompanyLine,
      getListMenu,
      areaProps,
      positionOptions,
      refCascader: cascaderRef,
      cityTrue,
      positionCityValue,
      // positionSelectArea,
      // positionCascaderRef,
      recommendedList,
      // positionList,
      communicationPositionList,
      buildingPositionList,
      servicePositionList,
      humanResourcesList,
      adminUrl: process.env.VUE_APP_ADMIN_URL,
      countCompany,
      statistical,
      leftAdv,
      rightAdv,
      informationLeftList,
      informationRightList,
      policyCarousel,
      policyLoading,
      advAnyClick,
      hotJobNameList,
      hotJobValue,
      hotJobHandler,
      bannerActivityAdv,
      activityActive,
      activityActiveClick,
      jobFairs,
      countType
    }
  }
})
</script>
<style lang="scss" scoped>
.top-banner {
  ::v-deep .el-carousel__container {
    width: 100%;
    height: 595px;
    margin: auto;
  }
}
.mainBox {
  ::v-deep .el-cascader {
    width: 160px !important;
  }
  .hotTitBor {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    &:hover {
      span {
        color: #f98f16;
      }
    }
  }
  .shStyle {
    background: #ffeeb7;
  }
  .wzStyle {
    background: #b7ffee;
  }
  .myStyle {
    background: #b7d1ff;
  }
  .rlzyStyle {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #ffddb7;
  }
}
.bannerActivity {
  bottom: 46px;
  left: 50%;
  z-index: 11;
  transform: translateX(-50%);
}
.cusor:hover {
  cursor: pointer;
}
.positionSearch {
  box-sizing: border-box;
  width: 1190px;
  position: absolute;
  bottom: -40px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  border-radius: 3px;
  ::v-deep .el-input__inner {
    border: 0px;
  }
  .srarchBtn {
    /* background-image: linear-gradient(274deg, #ee8031, #e6a551); */
    background: #f98f16;
  }
  ::v-deep .el-input__inner {
    font-size: 14px;
  }
  ::v-deep .el-cascader__dropdown {
    height: 240px;
  }
}
.policyCarousel {
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 30px;
  }
}
.ccc {
  border-bottom: 3px solid #9e9b91;
}
.leftImg {
  position: fixed;
  left: 20px;
  top: 450px;
}
.rightImg {
  position: fixed;
  right: 20px;
  top: 450px;
}
.titleNmae {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  width: 100%;
}
.description {
  position: absolute;
  top: 5px;
  right: 10px;
  background: rgba($color: #fff, $alpha: 0.4);
  box-shadow: 0 0 2px #fff;
  border-radius: 3px;
  padding: 5px;
}
</style>
