import request from '@/utils/request'
/* 行业树 */
export function listIndustryTree (data) {
  return request({
    url: '/basic/listIndustryTree',
    method: 'post',
    data: data
  })
}

/* 岗位树 */
export function listFunctionTree (data) {
  return request({
    url: '/basic/listFunctionTree',
    method: 'post',
    data: data
  })
}

/* 职类树 */
export function getPositionCategory (data) {
  return request({
    url: '/basic/getPositionCategory',
    method: 'post',
    data: data
  })
}

/* 添加用户浏览 */
export function addBrowseCount (data) {
  return request({
    url: '/basic/addBrowseCount',
    method: 'post',
    data: data
  })
}

/* 添加指定页面浏览次数 */
export function addBrowseTime (data) {
  return request({
    url: '/basic/addBrowseTime',
    method: 'post',
    data: data
  })
}

/* 获取用户浏览 */
export function getStatics () {
  return request({
    url: '/basic/getStatics',
    method: 'post'
  })
}

/* 获取大学生专区统计数据 */
export function getUniversityStatics () {
  return request({
    url: '/basic/getUniversityStatics',
    method: 'post',
    retApiRes: false
  })
}

/* 字典管理 */
export function getByParentCode (data) {
  return request({
    url: '/baseDict/getByParentCode',
    method: 'post',
    data: data
  })
}
/* 意见反馈 */
export function addFeedBack (data) {
  return request({
    url: '/basic/addFeedBack',
    method: 'post',
    data: data
  })
}
/* 生成意见反馈验证码 */
export function getImageVerificationCode (data) {
  return request({
    url: '/basic/getImageVerificationCode',
    method: 'post',
    data: data
  })
}
