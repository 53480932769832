<template>
      <span v-if="!minSalarys && !maxSalarys">薪资面议</span>
      <span v-if="minSalarys && minSalarys > 0">{{
        moneyTypes ==='Y' ? minSalarys * 1000 : 'K'
      }}</span>
      <!-- <span v-if="(minSalarys && minSalarys > 0) && (maxSalarys && maxSalarys > 0)"
        ></span
      > -->
      <span v-if="minSalarys && maxSalarys"> - </span>
      <span v-if="maxSalarys && maxSalarys > 0">{{
        moneyTypes ==='Y' ? maxSalarys * 1000 : 'K'
      }}</span>
      <span v-if="minSalarys || maxSalarys">{{moneyTypes ==='K' ? 'K' :'元'}}</span>
      <span v-if="maxSalarys || minSalarys">
        <span v-if="salaryUnits === 'Y'"> /年</span>
        <span v-if="salaryUnits === 'M'"> /月</span>
        <span v-if="salaryUnits === 'W'"> /周</span>
        <span v-if="salaryUnits === 'D'"> /天</span>
      </span>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'
export default defineComponent({
  name: 'MoneyFormatting',
  props: {
    minSalary: null,
    maxSalary: null,
    salaryUnit: null,
    moneyType: null // K   Y:元
  },
  setup (props, context) {
    const moneyOBJ = reactive({
      minSalarys: null,
      maxSalarys: null,
      salaryUnits: null,
      moneyTypes: null
    })
    watch(props, (newValue) => {
      moneyOBJ.minSalarys = newValue.minSalary
      moneyOBJ.maxSalarys = newValue.maxSalary
      moneyOBJ.salaryUnits = newValue.salaryUnit
      moneyOBJ.moneyTypes = newValue.moneyType
    }, { deep: true, immediate: true })
    return {
      ...toRefs(moneyOBJ)
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .wh60{
    width: 60px;
    height: 60px;
  }
  .wh100{
    width: 100px;
    height: 100px;
  }
</style>
