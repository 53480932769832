
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { infoList } from '@/api/consultInfo'
import { InfoListDetail } from '@/api/consultInfo/data.d'
import { parseData } from '@/utils/index'
export default defineComponent({
  name: 'LimitedRecruitment',
  setup (props, context) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    /* 获取资讯列表 */
    const informationList = ref<InfoListDetail[]>([])
    const getInfoList = async () => {
      const resp = await infoList({
        page: 1,
        pageSize: 10,
        type: 'LIMITED_TIME_RECRIUT'
      })
      informationList.value = resp.records
    }
    onMounted(() => {
      getInfoList()
    })
    return {
      informationList,
      parseData
    }
  }
})
