import request from '@/utils/request'
const BaseUrl = '/app/position'

/* 职位列表 */
export function listPosition (data) {
  return request({
    url: `${BaseUrl}/listPosition`,
    method: 'post',
    data: data
  })
}
/* 职位列表ES */
export function listPositionEs (data) {
  return request({
    url: `${BaseUrl}/listPositionEs`,
    method: 'post',
    data: data
  })
}

/* 新增职位 */
export function addPosition (data) {
  return request({
    url: `${BaseUrl}/addPosition`,
    method: 'post',
    data: data
  })
}
/* 编辑职位 */
export function editPosition (data) {
  return request({
    url: `${BaseUrl}/editPosition`,
    method: 'post',
    data: data
  })
}
/* 获取职位详情 */
export function getPositionDetail (data) {
  return request({
    url: `${BaseUrl}/getDetail`,
    method: 'post',
    data: data
  })
}
/* 获取职位详情 */
export function upOrDownPosition (data) {
  return request({
    url: `${BaseUrl}/upOrDownPosition`,
    method: 'post',
    data: data
  })
}
/* 收藏职位 */
export function addPositionCollection (data) {
  return request({
    url: `${BaseUrl}/addPositionCollection`,
    method: 'post',
    data: data
  })
}
/* 获取收藏职位列表 */
export function listPositionCollectionByMember (data) {
  return request({
    url: `${BaseUrl}/listPositionCollectionByMember`,
    method: 'post',
    data: data
  })
}
/* 个人收藏职位 */
export function getPositionCollectionByPositionId (data) {
  return request({
    url: `${BaseUrl}/getPositionCollectionByPositionId`,
    method: 'post',
    data: data
  })
}
/* 取消收藏职位 */
export function deletePositionCollection (data) {
  return request({
    url: `${BaseUrl}/deletePositionCollection`,
    method: 'post',
    data: data
  })
}
/* 推荐职位 */
export function recommend (data) {
  return request({
    url: `${BaseUrl}/recommand`,
    method: 'post',
    data: data
  })
}

/* 根据工作城市编码获取职位统计 */
export function staticsByJobCityCode (data) {
  return request({
    url: `${BaseUrl}/staticsByJobCityCode`,
    method: 'post',
    data: data,
    retApiRes: false
  })
}
/* 根据职位类别编码获取标签 */
export function listPositionCategoryTags (data) {
  return request({
    url: `${BaseUrl}/listPositionCategoryTags`,
    method: 'post',
    data: data,
    retApiRes: false
  })
}
/* 职位列表统计数据[ES] */
export function listPositionStaticsEs (data) {
  return request({
    url: `${BaseUrl}/listPositionStaticsEs`,
    method: 'post',
    data: data,
    retApiRes: false
  })
}
