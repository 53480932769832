<template>
      <el-row class="contentWarp">
      <!-- <el-col :span="6">
        <div class="ove flex align_item pt10 pb10">
          <router-link to="/">
            <img style="width: 90%" src="../assets/logo.png" />
          </router-link>
        </div>
      </el-col>
      <el-col :span="5" class="flex align_item">
          <img class="relative" style="width: 110%;left:-25px;" src="../assets/topImg/slogan.png" />
      </el-col> -->
      <el-col :span="11">
        <div class="ove flex align_item pt10 pb10">
          <router-link to="/">
            <img style="width: 90%" src="../assets/logo2.png" />
          </router-link>
        </div>
      </el-col>
      <el-col :span="13" class="flex space-around align_item">
          <!-- <img style="width: 55%" src="../assets/topImg/text.png" /> -->
           <div class="c333 fz18">
            <p class="borf3f3f3b pb5">云南省政府救助找工作岗位提供平台</p>
            <p class="pt5">云南省退役军人找工作岗位提供平台</p>
          </div>
          <div class="c333 fz18">
            <p class="borf3f3f3b pb5">云南省高校毕业生就业岗位提供平台</p>
            <p class="pt5">昆明市青年找工作岗位提供平台</p>
          </div>
      </el-col>

    </el-row>
</template>
